<template>
  <app-header></app-header>
  <section class="wrapper legal about_us">
    <nav-aside></nav-aside>
    <router-view></router-view>
  </section>
  <app-footer></app-footer>
</template>
<script>
import NavAside from '@/components/aboutUs/Nav.vue';
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
export default {
  name: 'LegalPage',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    'nav-aside': NavAside,
  },
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import '../assets/styles/pages/legal.scss';
@import '../assets/styles/pages/aboutUs.scss';
</style>
