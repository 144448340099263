<template>
  <section class="privacy_page">
    <h1>{{ $t('privacy.key001') }}</h1>
    <p>
      {{ $t('privacy.key002') }}
      <a href="https://www.htc.com/tw/terms/privacy/" target="_blank">
        <strong>{{ $t('privacy.key003') }}</strong>
      </a>
      {{ $t('privacy.key004') }}
    </p>

    <h2>{{ $t('privacy.key005') }}</h2>
    <p>{{ $t('privacy.key006') }}</p>

    <h2>{{ $t('privacy.key007') }}</h2>
    <p>{{ $t('privacy.key008') }}</p>
    <p>{{ $t('privacy.key009') }}</p>
    <p>{{ $t('privacy.key010') }}</p>

    <p>{{ $t('privacy.key011') }}</p>
    <br />
    <h2>{{ $t('legal.key007') }}</h2>
    <div class="file_btn">
      <i class="bi bi-download"></i>
      <a
        :href="`${$cloudFront}/Legal/PrivacyPolicy/20240126/%E9%9A%B1%E7%A7%81%E6%AC%8A%E6%94%BF%E7%AD%96_BEATDAY_zhTW_20240126.pdf`"
        target="_blank"
        download="隱私權政策_BEATDAY_zhTW_20240126.pdf"
      >
        隱私權政策_BEATDAY_zhTW_20240126.pdf
      </a>
    </div>
    <div class="file_btn">
      <i class="bi bi-download"></i>
      <a :href="`${$cloudFront}/Legal/PrivacyPolicy/20240126/Privacy%20Policy_BEATDAY_EN_20240126.pdf`" target="_blank" download="Privacy Policy_BEATDAY_EN_20240126.pdf">
        Privacy Policy_BEATDAY_EN_20240126.pdf
      </a>
    </div>
  </section>
</template>
<script>
export default {
  name: 'LegalPage',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
