<template>
  <aside>
    <h1>{{ $t('legal.key001') }}</h1>
    <nav>
      <ul>
        <li>
          <router-link :to="`/${currentLanguage}/legal/privacy`" active-class="active">
            {{ $t('legal.key004') }}
          </router-link>
        </li>
        <li>
          <a :href="cookiesUrl[currentLanguage] || cookiesUrl.default" target="_blank">
            {{ $t('legal.key003') }}
          </a>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/legal/user-policy`" active-class="active">
            {{ $t('legal.key002') }}
          </router-link>
        </li>
        <li>
          <a :href="productSecurityUrl[currentLanguage] || cookiesUrl.default" target="_blank">
            {{ $t('legal.key005') }}
          </a>
        </li>
        <!-- <li>
          <router-link
            :to="`/${currentLanguage}/legal/refund-policy`"
            active-class="active"
          >
            {{ $t("legal.key006") }}  退換貨條款
          </router-link>
        </li> -->
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'NavAside',
  data() {
    return {
      cookiesUrl: {
        en: 'https://www.htc.com/us/terms/cookies/',
        zh_tw: 'https://www.htc.com/tw/terms/cookies/',
        ja: 'https://www.htc.com/jp/terms/cookies/',
        default: 'https://www.htc.com/us/terms/cookies/',
      },
      productSecurityUrl: {
        en: 'https://www.htc.com/us/terms/product-security/',
        zh_tw: 'https://www.htc.com/tw/terms/product-security/',
        ja: 'https://www.htc.com/jp/terms/product-security/',
        default: 'https://www.htc.com/us/terms/product-security/',
      },
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
