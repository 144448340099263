const KEY = 'nickName';

function setNickname(nickName) {
  localStorage.setItem(KEY, nickName);
}

function getNickname() {
  return localStorage.getItem(KEY);
}

export { setNickname, getNickname };
