<template>
  <section class="">
    <div class="accordion-item addItem" :class="{ active: isActive }">
      <div class="accordion-heading" @click="toggleAccordion">
        <i :class="activeAccordion === cartItemIndex ? 'bi bi-caret-down-fill' : 'bi bi-caret-right-fill'"></i>
        {{ $t('shopping-cart.addOn_key001') }}
        <span>
          {{ purchaseText }}
          <i class="bi bi-chevron-right"></i>
        </span>
      </div>
      <div class="accordion-content">
        <div class="product products_item" :class="{ close: item.remainingQuantity === 0 }" v-for="(item, index) in groupAddOnItems" :key="index">
          <div class="left">
            <div class="item_img_box">
              <img :src="item.image" class="product-image" alt="product image" />
            </div>
            <div class="product-info item_content">
              <div class="direction">
                <div class="addon_item_name">
                  {{ item.title }}
                </div>
                <div class="item_dateTime">
                  {{ item.description }}
                </div>
              </div>
              <div class="item_price">
                <span class="current_price">NT$ {{ item.price }}</span>
                <div class="countAlert" v-if="item.remainingQuantity > 0 && item.remainingQuantity < 10" v-html="formattedAddOn_key010(item.remainingQuantity)"></div>
              </div>
            </div>
          </div>

          <div class="right" v-if="item.remainingQuantity != 0">
            <div class="chooseCount">
              <img src="../../assets/img/products/minus.svg" @click="decreaseTicketCount(index)" alt="" />
              <div class="count">{{ item.quantity }}</div>
              <img src="../../assets/img/products/plus.svg" @click="increaseTicketCount(index)" alt="" />
            </div>
          </div>
        </div>

        <div class="addCartBox">
          <p class="limit-info">{{ $t('shopping-cart.addOn_key002') }} {{ totalQuantity }}/{{ maxQuantity }}</p>
          <div class="addCart">
            <p class="limit-warning">
              {{ addAlert }}
            </p>
            <button class="submit_order button_style_linear" @click="addToCart">
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'EmptyPage',
  components: {},
  props: {
    product: Object,
    cartItemIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      addOnProductAlert: '',
      activeAccordion: null,
      isActive: true,
      totalQuantity: null,
      maxQuantity: null,
      addAlert: '',
      groupAddOnItems: [],
    };
  },
  mounted() {
    this.addOnItemGroups = this.product.addOnItemGroups[0] || [];

    this.initializeProducts();
    this.calaTotalQuantity();
    this.calaMaxQuantity();
  },
  computed: {
    buttonText() {
      return this.product.addOnItems.length === 0 ? this.$t('shopping-cart.addOn_key003') : this.$t('shopping-cart.addOn_key004');
    },
    purchaseText() {
      return this.product.addOnItems.length === 0 ? this.$t('shopping-cart.addOn_key005') : this.$t('shopping-cart.addOn_key006');
    },
  },
  methods: {
    formattedAddOn_key010(itemQuantity) {
      return this.$t('shopping-cart.addOn_key010', {
        quantity: `<span class='current_price'> ${itemQuantity} </span>`,
      });
    },
    initializeProducts() {
      this.addOnItemGroups.addOnItems = this.addOnItemGroups.addOnItems.map((item) => {
        return {
          ...item,
          quantity: item.quantity || 0,
        };
      });
      this.groupAddOnItems = this.addOnItemGroups.addOnItems || [];
    },
    calaTotalQuantity() {
      this.totalQuantity = this.groupAddOnItems.reduce((total, item) => {
        return total + (item.quantity || 0);
      }, 0);

      return this.totalQuantity;
    },
    calaMaxQuantity() {
      return (this.maxQuantity = this.addOnItemGroups.groupQuantityLimit * this.product.quantity);
    },
    increaseTicketCount(index) {
      if (this.totalQuantity + 1 > this.maxQuantity) {
        this.addAlert = '＊超過商品加購上限';
        return;
      }
      this.addOnItemGroups.addOnItems[index].quantity += 1;
      this.calaTotalQuantity();
      this.addAlert = '';
      if (this.totalQuantity > this.maxQuantity) {
        this.addAlert = this.$t('shopping-cart.addOn_key007');
      }
    },
    decreaseTicketCount(index) {
      if (this.groupAddOnItems[index].quantity > 0) {
        this.groupAddOnItems[index].quantity -= 1;
        this.calaTotalQuantity();
        if (this.totalQuantity <= this.maxQuantity) {
          this.addAlert = '';
        }
      }
    },
    addToCart() {
      if (this.totalQuantity === 0) {
        this.addAlert = this.$t('shopping-cart.addOn_key008');
      } else if (this.totalQuantity <= this.maxQuantity) {
        const cartItemIndex = this.cartItemIndex;
        const addOnItemsToAdd = this.groupAddOnItems.filter((item) => item.quantity > 0); // 只选择有数量的加购商品

        this.$emit('add-to-cart', { cartItemIndex, addOnItemsToAdd });

        this.toggleAccordion();
      }
    },
    checkQuantity() {
      this.calaMaxQuantity();
      if (this.totalQuantity > this.maxQuantity) {
        alert(this.$t('shopping-cart.addOn_key009'));
        this.addAlert = this.$t('shopping-cart.addOn_key007');
        const cartItemIndex = this.cartItemIndex;
        const addOnItemsToAdd = [];
        this.$emit('add-to-cart', { cartItemIndex, addOnItemsToAdd });
        this.isActive = true;
      }
    },
    toggleAccordion() {
      if (this.isActive) {
        this.$nextTick(() => {
          $(this.$el).find('.accordion-content').slideUp();
        });
      } else {
        this.$nextTick(() => {
          $(this.$el).find('.accordion-content').slideDown();

          $('html, body').animate(
            {
              scrollTop: $(this.$el).offset().top - 230,
            },
            500,
          );
        });
      }
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style lang="scss"></style>
