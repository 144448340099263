<template>
  <div class="loading-mask">
    <div class="loading_box">
      <div class="loading_icon"></div>
      <div class="loading_text">Loading</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIcon',
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.8);
  z-index: 1200; /* $z-index-overlay */
}

.loading_icon {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
  color: #ccc;
  animation: rotation 1s linear infinite;
  margin-bottom: 30px;
}

.loading_icon:after,
.loading_icon:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 0px;
  margin-left: 13px;
  background-color: #e79fff;
  border-radius: 50%;
  animation: scale50 1s infinite ease-in-out;
}

.loading_icon:before {
  top: -10px;
  bottom: auto;
  background-color: #9122c2;
  animation-delay: 0.5s;
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale50 {
  50% {
    transform: scale(0.2);
  }
}

.loading_text {
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
  animation: 1s color infinite linear;
  color: rgb(255, 255, 255, 0.5);
}

@keyframes color {
  0% {
    color: rgb(255, 255, 255, 0.5);
  }

  50% {
    color: rgb(255, 255, 255, 0.1);
  }
}
</style>
