<template>
  <aside>
    <h1>{{ $t('about-us.key001') }}</h1>
    <nav>
      <ul>
        <li>
          <router-link :to="`/${currentLanguage}/aboutUs/about-beatday`" active-class="active">
            {{ $t('about-us.key002') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/aboutUs/business-cooperation`" active-class="active">
            {{ $t('about-us.key003') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/aboutUs/contact-us`" active-class="active">
            {{ $t('about-us.key004') }}
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'NavAside',
  data() {
    return {
      cookiesUrl: {
        en: 'https://www.htc.com/us/terms/cookies/',
        zh_tw: 'https://www.htc.com/tw/terms/cookies/',
        ja: 'https://www.htc.com/jp/terms/cookies/',
        default: 'https://www.htc.com/us/terms/cookies/',
      },
      productSecurityUrl: {
        en: 'https://www.htc.com/us/terms/product-security/',
        zh_tw: 'https://www.htc.com/tw/terms/product-security/',
        ja: 'https://www.htc.com/jp/terms/product-security/',
        default: 'https://www.htc.com/us/terms/product-security/',
      },
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
