<template>
  <aside>
    <h1>{{ $t('faq.key001') }}</h1>
    <nav>
      <ul>
        <li>
          <router-link :to="`/${currentLanguage}/faq/account-issues`" active-class="active">
            {{ $t('faq.key002') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/faq/ticket-issues`" active-class="active">
            {{ $t('faq.key003') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/faq/redemption-guide`" active-class="active">
            {{ $t('faq.key004') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/faq/beatday-usage`" active-class="active">
            {{ $t('faq.key005') }}
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'NavAside',
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
