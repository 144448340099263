<template>
  <section class="privacy_page aboutBeatday">
    <div class="paragraph">
      <h1>{{ $t('about-us.key002') }}</h1>
      <img src="@/assets/img/public/BEATDAY_AlternateLogo_Multicolor.svg" alt="BEATDAY_logo" />
      <p>
        {{ $t('about-beatday.key001') }}
      </p>
    </div>
    <div class="paragraph">
      <h1>{{ $t('about-beatday.key002') }}</h1>
      <img src="@/assets/img/public/VIVE_Originals_AlternateLogo_Digital_Primary_multicolor_White.svg" alt="VIVE_Originals_logo" class="VIVE_Originals" />
      <p>
        {{ $t('about-beatday.key003') }}
      </p>
    </div>
  </section>
</template>
<script>
export default {
  name: 'LegalPage',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
