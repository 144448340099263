<template>
  <section class="account-binding account-setting">
    <h1>{{ $t('serial-number.account-binding_key001') }}</h1>
    <div class="filter-section">
      <router-link to="">
        <button class="filter-button active">
          {{ $t('serial-number.account-binding_key002') }}
        </button>
      </router-link>
      <router-link :to="`/${currentLanguage}/member/serial-number/registration-binding`">
        <button class="filter-button">
          {{ $t('serial-number.account-binding_key003') }}
        </button>
      </router-link>
    </div>

    <form class="login_box" v-if="showLogin" @submit.prevent="login">
      <label class="">
        <span class="title">{{ $t('serial-number.account-binding_key004') }}</span>
        <input type="text" v-model="username" :placeholder="$t('serial-number.account-binding_key005')" name="username" />
      </label>

      <label class="">
        <span class="title">{{ $t('serial-number.account-binding_key006') }}</span>
        <div class="eye_fixed">
          <input :type="passwordVisible ? 'text' : 'password'" v-model="password" :placeholder="$t('serial-number.account-binding_key007')" name="passwordNew" />
          <div class="eye_btn" @click="togglePasswordVisibility('new')">
            <i class="bi" :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i>
          </div>
        </div>
      </label>

      <div class="alert">{{ alert }}</div>
      <button type="submit" class="submit-button">
        {{ $t('serial-number.account-binding_key008') }}
      </button>
    </form>

    <div v-if="loginSuccess" class="loginSuccess">
      <div class="flex">
        <h2 class="title">
          {{ $t('serial-number.account-binding_key009') }}
        </h2>
        <dotlottie-player
          id="check-box"
          src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
          background="transparent"
          speed="1"
          autoplay=""
        ></dotlottie-player>
      </div>
      <p>
        {{
          $t('serial-number.account-binding_key010', {
            countdownToHome,
          })
        }}
        <router-link :to="`/${currentLanguage}/member/serial-number/serial-number-default`">
          {{ $t('serial-number.account-binding_key011') }}
        </router-link>
      </p>
    </div>
  </section>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { basicLogin, bindAccount } from '@/api/apiService';
import Loading from '@/components/Loading.vue';

export default {
  name: 'AccountBinding',
  components: {
    Loading,
  },
  computed: {
    jwt() {
      return this.$store.state.jwt;
    },
  },
  data() {
    return {
      username: '',
      password: '',
      alert: '',
      passwordVisible: false,
      showLogin: true,
      loginSuccess: false,
      countdownToHome: 5,
      loading: false,
    };
  },
  methods: {
    async login() {
      if (!this.username || !this.password) {
        this.alert = this.$t('serial-number.account-binding_key012');
        return;
      } else {
        try {
          const loginInput = {
            account: this.username,
            password: this.password,
          };

          this.loading = true;
          const oldJwt = this.jwt;
          await basicLogin(loginInput);
          await bindAccount(this.username, oldJwt);

          // for getting new jwt
          await basicLogin(loginInput);

          this.alert = '';
          this.playAnimation();
          this.loginSuccess = true;
          this.showLogin = false;

          const countdownInterval = setInterval(() => {
            if (this.countdownToHome > 0) {
              this.countdownToHome--;
            } else {
              clearInterval(countdownInterval);
              this.$router.push(`/${this.currentLanguage}/member/serial-number`);
            }
          }, 1000);
        } catch (error) {
          this.alert = this.$t('serial-number.account-binding_key013');
        } finally {
          this.loading = false;
        }
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    playAnimation() {
      this.$nextTick(() => {
        const checkBox = document.querySelector('#check-box');
        if (checkBox) {
          setInterval(() => {
            checkBox.play();
          }, 0);
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
