<template>
  <section class="ticket-section">
    <h1>
      {{ $t('ticket-section.key001') }}
    </h1>

    <div class="filter-section">
      <button
        v-for="category in categories"
        :key="category"
        :class="{
          'filter-button': true,
          active: currentCategory === category,
        }"
        @click="changeCategory(category)"
      >
        {{ category === 'all' ? $t('ticket-section.key002') : category === 'unused' ? $t('ticket-section.key003') : $t('ticket-section.key004') }}
      </button>
    </div>

    <div class="ticket-list" v-if="!empty">
      <div v-for="ticket in filteredTickets" :key="ticket.Id" class="ticket-item">
        <div class="item-info">
          <img :src="ticket.Pic1" alt="" />
          <div class="text">
            <p class="ticket" v-if="ticket.Qty > 1">
              <img src="../../assets/img/member/tickets.svg" alt="" />
              {{ ticket.Qty }}
            </p>
            <h2 class="title">{{ ticket.ShowName }}</h2>
            <h3>{{ ticket.Detail }}</h3>
          </div>
        </div>
        <button class="go-to" :class="{ close: !ticket.Valid }" @click="goToUsage(ticket.Id)">
          {{ ticket.Valid === false ? $t('ticket-section.key004') : $t('ticket-section.key005') }}
        </button>
      </div>
    </div>
    <div v-if="empty" class="empty">
      <h2>
        {{ $t('ticket-section.key006') }}
        <router-link :to="`/${currentLanguage}/member/serial-number/serial-number-default`">
          <span>{{ $t('ticket-section.key007') }}</span>
        </router-link>
      </h2>
    </div>
    <info-popup v-if="selectedTicket" :ticket="selectedTicket" @close-info-popup="closeInfoPopup"></info-popup>
  </section>
  <Loading v-show="loading"></Loading>
</template>

<script>
import InfoPopup from '@/components/memberCenter/InfoPopup.vue';
import { getTicketInfo } from '@/api/apiService';
import Loading from '@/components/Loading.vue';
import { mapState } from 'vuex';

export default {
  name: 'TicketSection',
  components: {
    'info-popup': InfoPopup,
    Loading,
  },
  computed: {
    // 篩選「所有票券、尚未使用、已過期」
    filteredTickets() {
      return this.tickets.filter((ticket) => {
        if (this.currentCategory === 'all') {
          return true;
        } else if (this.currentCategory === 'unused') {
          return ticket.Valid === true && ticket.isRedeemed === false; //尚未使用
        } else if (this.currentCategory === 'expired') {
          return ticket.Valid === false || ticket.isRedeemed === true; //已過期
        }
      });
    },
    ...mapState(['jwt']),
  },
  mounted() {
    if (this.jwt) {
      this.fetchTickets();
    } else {
      this.$router.push({
        path: `/${this.currentLanguage}/member-login/login`,
      });
    }
  },
  data() {
    return {
      loading: false,
      categories: ['all', 'unused', 'expired'],
      currentCategory: 'all',
      tickets: [],
      empty: false,
      displayAlert: false,
      selectedTicket: null,
    };
  },
  methods: {
    async fetchTickets() {
      if (this.jwt) {
        let response;
        try {
          this.loading = true;
          response = await getTicketInfo(this.jwt);
          const responseData = response.data.data;

          const proxyResponse = Object.values(responseData);
          this.tickets = [...proxyResponse];
          if (this.tickets.length === 0) {
            this.empty = true;
          }
          this.tickets = proxyResponse.map((ticket) => ({
            ...ticket,
            isRedeemed: ticket.Used >= ticket.Qty,
          }));
        } catch (error) {
          alert(error);
        } finally {
          this.loading = false;
        }
      }
    },
    changeCategory(category) {
      this.currentCategory = category;
    },
    goToUsage(ticketId) {
      document.body.style.overflow = 'hidden';
      this.selectedTicket = this.filteredTickets.find((ticket) => ticket.Id === ticketId);
      this.$emit('show-info-popup');
    },
    closeInfoPopup() {
      document.body.style.overflow = '';
      this.selectedTicket = null;
    },
    showAlert() {
      document.body.style.overflow = 'hidden';
      this.displayAlert = true;
    },
    hideAlert() {
      document.body.style.overflow = '';
      this.displayAlert = false;
    },
  },
};
</script>
