import { createI18n } from 'vue-i18n';
import zhTW from './zh_tw.json';
import * as CurrentLanguageStorage from '@/localStorage/currentLanguageStorage';

const FALLBACK_LANG = 'zh_tw';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: CurrentLanguageStorage.get() || FALLBACK_LANG, // Default locale
  fallbackLocale: FALLBACK_LANG,
  messages: {
    zh_tw: zhTW,
  },
});

export async function loadLanguageAsync(lang) {
  if (i18n.global.availableLocales.includes(lang)) {
    i18n.global.locale.value = lang;
    return lang;
  }

  if (lang === FALLBACK_LANG) {
    i18n.global.locale.value = FALLBACK_LANG;
    return FALLBACK_LANG;
  }

  try {
    const messages = await import(`./${lang}.json`);

    if (messages && messages.default) {
      i18n.global.setLocaleMessage(lang, messages.default);
      i18n.global.locale.value = lang;
    } else {
      console.warn(`Language file for ${lang} is empty or invalid.`);
      i18n.global.locale.value = FALLBACK_LANG;
    }
  } catch (error) {
    console.error(`Failed to load language file for ${lang}. Falling back to ${FALLBACK_LANG}.`, error);
    i18n.global.locale.value = FALLBACK_LANG;
  }

  return i18n.global.locale.value;
}

export default i18n;
