<template>
  <transition name="fade">
    <section class="cookie" v-if="showCookieConsent">
      <p>
        {{ $t('common.cookie_key001') }}
        <a :href="this.cookieUrl[currentLanguage] || link.url['default']" target="_blank">
          {{ $t('common.footer_key003') }}
        </a>
      </p>
      <div class="cookie-consent__actions">
        <button @click="rejectCookies" class="cookie-consent__button reject">
          {{ $t('common.cookie_key002') }}
        </button>
        <button @click="acceptCookies" class="cookie-consent__button agree">
          {{ $t('common.cookie_key003') }}
        </button>
      </div>
    </section>
  </transition>
</template>
<script>
export default {
  name: 'CookieAgree',
  components: {},
  data() {
    return {
      showCookieConsent: false,
      cookieUrl: {
        en: 'https://www.htc.com/us/terms/cookies/',
        zh_tw: 'https://www.htc.com/tw/terms/cookies/',
        ja: 'https://www.htc.com/jp/terms/cookies/',
        default: 'https://www.htc.com/us/terms/cookies/',
      },
    };
  },
  props: {},
  computed: {},
  mounted() {
    this.checkCookieConsent();
  },
  methods: {
    checkCookieConsent() {
      const cookieConsent = localStorage.getItem('cookieConsent');

      if (cookieConsent === 'accepted') {
        this.initializeGoogleAnalytics(); // 用戶同意後加載 GA
        this.initializeGoogleTagManager(); // 用戶同意後加載 GTM
      } else if (cookieConsent === 'rejected') {
        this.showCookieConsent = false;
      } else {
        this.showCookieConsent = true;
      }
    },
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'accepted');
      this.showCookieConsent = false;
      this.initializeGoogleAnalytics(); // 用戶同意後加載 GA
      this.initializeGoogleTagManager(); // 用戶同意後加載 GTM
    },
    rejectCookies() {
      localStorage.setItem('cookieConsent', 'rejected');
      this.showCookieConsent = false;
    },
    initializeGoogleAnalytics() {
      const gaId = process.env.Google_Analytics_ID;

      // Google Analytics
      const scriptGA = document.createElement('script');
      scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      scriptGA.async = true;
      document.head.appendChild(scriptGA);

      scriptGA.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', gaId);
      };
    },
    initializeGoogleTagManager() {
      const gtmId = process.env.Google_Tag_Manager_ID;
      // 1. 動態加載 Google Tag Manager JavaScript
      const scriptGTM = document.createElement('script');
      scriptGTM.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
    })(window,document,'script','dataLayer','${gtmId}');`;
      document.head.appendChild(scriptGTM);

      // 2. 插入 <noscript> 部分的 iframe（適合沒有 JavaScript 的情況）
      const noScriptGTM = document.createElement('noscript');
      noScriptGTM.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5SN8DD84" 
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      // 插入到 body 的第一個子元素
      document.body.insertBefore(noScriptGTM, document.body.firstChild);
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/pages/index.scss';
</style>
