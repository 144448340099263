<template>
  <app-header></app-header>
  <div class="wrapper section member registerMemberInfo">
    <member-info :edit="true" :registerEdit="true"></member-info>
  </div>
  <app-footer></app-footer>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import MemberInformation from '@/pages/memberCenter/MemberInformation.vue';

export default {
  name: 'MemberPage',
  components: {
    'member-info': MemberInformation,
    'app-header': AppHeader,
    'app-footer': AppFooter,
  },
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style lang="scss">
@import '../../assets/styles/pages/memberCenter/memberInformation.scss';
</style>
