<template>
  <div class="register">
    <h1 class="title" v-if="!registrationSuccess" v-show="!guest_order_rgs">
      {{ $t('register.key001') }}
    </h1>
    <form @submit.prevent="register" v-if="!registrationSuccess">
      <div class="username">
        <i class="bi bi-person-fill" :class="{ disable: verifyShow || verifySuccess }"></i>
        <input v-model="usernameR" type="text" id="username" name="username" :placeholder="$t('register.key002')" :disabled="verifyShow || verifySuccess" />
        <div class="verify_email" v-if="verifyShow">
          <span>{{ $t('register.key003') }}</span>
          <span class="change_email" @click="changeEmail">
            {{ $t('register.key004') }}
          </span>
        </div>
        <div class="verifySuccess verify_email" v-if="verifySuccess">
          <p>
            <i class="bi bi-check-circle-fill"></i>
            &nbsp;{{ $t('register.key005') }}
          </p>
        </div>
      </div>
      <div class="verify" v-if="verifyShow">
        <i class="bi bi-shield-fill-exclamation"></i>
        <input v-model="verifyR" type="text" id="verify" name="verify" :placeholder="$t('register.key006')" />
        <div class="verify_email">
          <p class="resend" :class="{ disResend: !resendShow }" @click="resendCode">
            <i class="bi bi-arrow-clockwise"></i>
            <span class="sendText">{{ $t('register.key007') }}</span>
            <span v-if="!resendShow">&nbsp;{{ countdown }}s</span>
          </p>
          <span class="verify_time"> {{ $t('register.key008') }} {{ formatTime(timer) }} </span>
        </div>
        <div class="alert alertVerify">{{ alertVerify }}</div>
      </div>

      <div class="password">
        <i class="bi bi-key-fill"></i>
        <input v-model="passwordR" :type="passwordVisible ? 'text' : 'password'" id="password" name="password" :placeholder="$t('register.key009')" />
        <div class="eye_btn" @click="togglePasswordVisibility">
          <i class="bi" :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i>
        </div>
      </div>

      <div class="password">
        <i class="bi bi-key-fill"></i>
        <input v-model="passwordCheck" :type="passwordVisible ? 'text' : 'password'" id="passwordCheck" name="password" :placeholder="$t('register.key010')" />
        <div class="eye_btn" @click="togglePasswordVisibility">
          <i class="bi" :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i>
        </div>
      </div>

      <div class="notification">
        <input type="checkbox" id="notification" v-model="notificationAgree" />
        <label for="notification">
          {{ $t('register.key011') }}
        </label>
      </div>
      <div class="privacy">
        <p v-html="formattedMessage"></p>
      </div>

      <div class="alert">{{ alertR }}</div>
      <button type="button" class="login_btn" v-show="(!verifyShow && !verifySuccess) || (verifyShow && verifySuccess)" @click="sandVerifyCode">
        {{ $t('register.key013') }}
      </button>
      <button type="submit" class="login_btn" v-show="(verifyShow && !verifySuccess) || (!verifyShow && verifySuccess)">
        {{ $t('register.key014') }}
      </button>

      <div class="link">
        <router-link :to="`/${currentLanguage}/member-login/login`">
          {{ $t('register.key015') }}
        </router-link>
      </div>
    </form>

    <div v-if="registrationSuccess" class="registration-success">
      <h1 class="title">{{ $t('register.key016') }}</h1>
      <dotlottie-player
        id="check-box"
        src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
        background="transparent"
        speed="1"
        autoplay=""
      ></dotlottie-player>
      <p>{{ $t('register.key017', { countdownJump }) }}</p>
      <router-link :to="`/${currentLanguage}/member-login/login`">
        <button class="login_btn">{{ $t('register.key018') }}</button>
      </router-link>
    </div>
  </div>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { sendVerification, resendVerification, signUp } from '@/api/apiService';
import Loading from '@/components/Loading.vue';

export default {
  name: 'RegisterRegion',
  props: {
    username: {
      type: String,
      default: '',
    },
  },
  emits: ['stageUpdated'],
  components: {
    Loading,
  },
  data() {
    return {
      usernameR: this.username,
      passwordR: '',
      passwordCheck: '',
      passwordVisible: false,
      notificationAgree: false,
      verifyR: '',
      alertR: '',
      alertVerify: '',
      registrationSuccess: false,
      verifySuccess: false,
      loading: false,
      verifyShow: false,
      resendShow: false,
      countdownJump: 5,
      countdown: 0,
      timer: 0,
      guest_order_rgs: false,
      stageCompleted: false,
    };
  },

  mounted() {
    if (this.registrationSuccess) {
      this.playAnimation();
    }
    if (this.$route.name === 'OrderComplete') {
      this.guest_order_rgs = true;
    }
  },
  computed: {
    formattedMessage() {
      return this.$t('register.key012', {
        userPolicyLink: `<a href="#/${this.currentLanguage}/legal/user-policy" target="_blank">${this.$t('register.key025')}</a>`,
        privacyPolicyLink: `<a href="#/${this.currentLanguage}/legal/privacy" target="_blank">${this.$t('register.key026')}</a>`,
        serviceTermsLink: `<a href="#/${this.currentLanguage}/legal/user-policy" target="_blank">${this.$t('register.key027')}</a>`,
      });
    },
  },
  methods: {
    // order-complete Pass
    updateStage(type) {
      this.stageCompleted = type;
      this.$emit('stageUpdated', this.stageCompleted);
    },
    //更改信箱
    changeEmail() {
      this.verifyShow = false;
      this.resendShow = false;
      this.updateStage(false);
      this.stopCountdown();
    },
    //驗證碼發送_倒數
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.interval);
          this.resendShow = true;
        }
      }, 1000);
    },
    //驗證碼期限_倒數
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.timerInterval);
          this.alertVerify = this.$t('register.key019');
        }
      }, 1000);
    },
    //驗證碼期限_format
    formatTime(seconds) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;
      if (h > 0) {
        return `${h}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
      } else {
        return `${m}:${s.toString().padStart(2, '0')}`;
      }
    },
    //重設倒數時間 > 驗證碼發送 & 驗證碼期限
    resetCountdown() {
      this.countdown = 60;
      this.timer = 600;
    },
    //停止倒數
    stopCountdown() {
      clearInterval(this.interval);
      clearInterval(this.timerInterval);
    },
    //發送驗證碼
    async sandVerifyCode() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.usernameR)) {
        this.alertR = this.$t('register.key020');
        return;
      } else {
        this.alertR = '';
        this.alertVerify = '';
        this.updateStage(true);
        try {
          const accountData = {
            account: this.usernameR,
          };
          this.loading = true;
          await sendVerification(accountData);
          this.verifyShow = true;
          this.resetCountdown();
          this.startCountdown();
          this.startTimer();
        } catch (error) {
          const statusCode = error?.response?.status;
          if (statusCode === this.$statusCodes.BAD_REQUEST) {
            this.alertR = this.$t('register.key028');
          } else if (statusCode === this.$statusCodes.Forbidden) {
            const accountData = {
              account: this.usernameR,
            };
            try {
              await resendVerification(accountData);
              this.verifyShow = true;
              this.resetCountdown();
              this.startCountdown();
              this.startTimer();
            } catch (error) {
              this.alertR = this.$t('register.key028');
            }
          } else if (statusCode === this.$statusCodes.Unauthorized) {
            this.alertR = this.$t('register.key031');
          } else {
            this.alertR = this.$t('register.key028');
          }
        } finally {
          this.loading = false;
        }
      }
    },
    //重新發送驗證碼
    async resendCode() {
      try {
        this.alertVerify = '';
        const accountData = {
          account: this.usernameR,
        };
        this.loading = true;
        await resendVerification(accountData);
        this.stopCountdown();
        this.resetCountdown();
        this.startCountdown();
        this.startTimer();
        this.resendShow = false;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.alertVerify = this.$t('register.key028');
      }
    },
    register() {
      // 1. 檢查密碼是否符合長度8-16位
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
      if (!passwordRegex.test(this.passwordR)) {
        this.alertR = this.$t('register.key022');
        return;
      }

      // 2. 檢查兩次輸入的密碼是否一致
      if (this.passwordR !== this.passwordCheck) {
        this.alertR = this.$t('register.key023');
        return;
      }
      // 3. 檢查“驗證碼”是否空白
      if (this.verifyR === '') {
        this.alertR = this.$t('register.key030');
        return;
      } else {
        this.alertR = '';
        this.signup();
      }
    },
    async signup() {
      try {
        const registerData = {
          account: this.usernameR,
          password: this.passwordR,
          code: this.verifyR,
        };
        this.loading = true;
        await signUp(registerData);
        this.loading = false;
        this.registrationSuccess = true;

        const countdownInterval = setInterval(() => {
          if (this.countdownJump > 0) {
            this.countdownJump--;
          } else {
            clearInterval(countdownInterval);
            this.$router.push({
              path: `/${this.currentLanguage}/member-login/login`,
            });
          }
        }, 1000);
      } catch (error) {
        this.loading = false;
        const statusCode = error.response.status;
        if (statusCode === this.$statusCodes.BadRequest) {
          this.alertVerify = this.$t('register.key029');
        } else {
          this.alertVerify = this.$t('register.key028');
        }
      }
    },

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },

    playAnimation() {
      this.$nextTick(() => {
        const checkBox = document.querySelector('#check-box');
        if (checkBox) {
          setInterval(() => {
            checkBox.play();
          }, 0);
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
