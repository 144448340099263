<!-- InfoPopup.vue -->
<template>
  <div class="info-popup modal">
    <div class="mask"></div>
    <div class="modal" @click.stop>
      <!-- Type : QRCode -->
      <div v-if="ticket.Type === 1" class="text">
        <div v-if="!serialSuccess">
          <div class="QRCode_box">
            <vue-qr class="qr-code" :text="qrCodeUrl" :size="500" />
            <p @click="refreshQrCode">{{ formattedTime }} {{ $t('ticket-section.key008') }}<img src="../../assets/img/member/reset.svg" alt="" class="resetIcon" /></p>
          </div>
          <h2>{{ ticket.ShowName }}</h2>
          <h3>{{ ticket.Name }}</h3>
          <p v-html="ticket.Detail"></p>
        </div>
        <div v-if="serialSuccess" class="serialSuccess">
          <dotlottie-player
            id="check-box"
            src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
            background="transparent"
            speed="1"
            autoplay=""
          ></dotlottie-player>
          <h2>{{ $t('ticket-section.key009') }}</h2>
        </div>
      </div>

      <!-- Type : Text -->
      <div v-else class="text">
        <h2>{{ ticket.ShowName }}</h2>
        <h3>{{ ticket.Name }}</h3>
        <p v-html="ticket.Detail"></p>
      </div>

      <div @click="closeClick" class="close">
        <h2 class="close_icon">{{ $t('ticket-section.key010') }} &times;</h2>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import { checkTicketState, getUserInfo } from '@/api/apiService';
import { mapState } from 'vuex';

const baseURL = process.env.URL_DOMAIN;

export default {
  props: {
    ticket: Object,
  },
  components: { vueQr },
  data() {
    return {
      uid: '',
      qrCodeUrl: '',
      remainingTime: 5 * 60,
      intervalId: null,
      serialSuccess: false,
      timestamp: '',
      startCompare: true,
    };
  },
  mounted() {
    this.refreshQrCode();
    this.setRefreshInterval();
    this.setUpdateTicket();
    this.intervalId = setInterval(this.updateTimer, 1000);
  },
  beforeUnmount() {
    clearInterval(this.refreshInterval);
    clearInterval(this.ticketInterval);
  },
  computed: {
    imageUrl() {
      return this.ticket ? this.ticket.Pic1 : '';
    },
    formattedTime() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    ...mapState(['jwt', 'userId']),
  },
  methods: {
    async refreshQrCode() {
      try {
        this.remainingTime = 5 * 60;
        if (!this.jwt) {
          this.$store.dispatch('logout');
          this.$router.push(`/${this.currentLanguage}/member-login/login`);
          return;
        }

        if (!this.userId) {
          await getUserInfo(this.jwt);
        }

        const randomParam = Math.random().toString(36).substring(2, 5);
        const timestamp = Date.now();
        this.timestamp = timestamp;

        this.qrCodeUrl = `${baseURL}?random=${randomParam}&uid=${this.userId}&tid=${this.ticket.Id}&timestamp=${timestamp}`;
      } catch (error) {
        console.error(error);
      }
    },
    async updateTicket() {
      if (this.jwt && this.startCompare) {
        const response = await checkTicketState(this.timestamp, this.jwt);
        const responseData = response.data.data;

        if (responseData?.result) {
          this.serialSuccess = true;
          this.startCompare = false;
          this.$nextTick(() => {
            const checkBox = document.querySelector('#check-box');
            if (checkBox) {
              setInterval(() => {
                checkBox.play();
              }, 0);
            }
          });
        }
      }
    },
    setRefreshInterval() {
      this.refreshInterval = setInterval(this.refreshQrCode, 60000);
    },
    async setUpdateTicket() {
      if (this.ticket.Type === 1) {
        this.ticketInterval = setInterval(this.updateTicket, 5000);
      }
    },
    updateTimer() {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        clearInterval(this.intervalId);
      }
    },
    closeClick() {
      this.$emit('close-info-popup');
      this.serialSuccess = false;
    },
  },
};
</script>
