// 路由配置
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/vuex';
import { loadLanguageAsync } from '@/assets/i18n';

//--------------------- Header ---------------------//
import RouterViewPage from './pages/RouterView.vue'; // RouterView
import Index from './pages/IndexPage.vue'; // 首頁

//*** Products & ShoppingCart ***//
import Products from './pages/products/Products.vue'; // 新購票中心
import ProductInfo from './pages/products/ProductsInfo.vue'; // 新商品頁
import ShoppingCart from './pages/ShoppingCart.vue'; // 新商品頁
import OrderComplete from './pages/OrderComplete.vue'; // 訂單完成頁面

//*** FAQ ***//
import FAQ from './pages/FAQ.vue'; // 常見問題
import AccountIssues from './pages/faq/AccountIssues.vue'; //帳號問題
import TicketIssues from './pages/faq/TicketIssues.vue'; //票務問題
import RedemptionGuide from './pages/faq/RedemptionGuide.vue'; //兑票教學
import BeatdayUsage from './pages/faq/BeatdayUsage.vue'; //BEATDAY使用
// import Maintenance from './pages/MaintenancePage.vue'; // Error
//*** About Us ***//
import AboutUs from './pages/AboutUs.vue'; //關於我們 RouterView
import AboutBeatday from './pages/aboutUs/AboutBeatday.vue'; // 關於 BEATDAY
import BusinessCooperation from './pages/aboutUs/BusinessCooperation.vue'; //商務合作
import ContactUs from './pages/aboutUs/ContactUs.vue'; //聯繫我們

//--------------------- Footer ---------------------//
//*** Legal ***//
import Legal from './pages/Legal.vue';
import Privacy from './pages/legal/Privacy.vue';
import UserPolicy from './pages/legal/UserPolicy.vue';
import RefundPolicy from './pages/legal/RefundPolicy.vue';
import ReturnPolicyMizukiLin from './pages/legal/ReturnPolicy_MizukiLin.vue'; //林襄退換貨規則
import ReturnPolicy_VDay from './pages/legal/ReturnPolicy_VDay.vue'; //VDay 退換貨規則
import ReturnPolicy_MiruWonderland from './pages/legal/ReturnPolicy_MiruWonderland.vue'; //米嚕節目退換貨規則
//*** Download ***//
import DownloadPage from './pages/DownloadPage.vue'; // 程式下載

//--------------------- Login ---------------------//
import MemberLogin from './pages/LoginPage.vue'; // Login_RouterView
import Login from '@/pages/login/Login.vue'; // 登入
import Register from '@/pages/login/Register.vue'; // 註冊
import ForgotPassword from '@/pages/login/ForgotPassword.vue'; // 忘記密碼
import RegisterMemberInfo from '@/pages/login/RegisterMemberInfo.vue'; // 必填會員資料

//--------------------- MemberCenter ---------------------//
import Member from './pages/MemberPage.vue'; // 會員中心_RouterView
import TicketSection from '@/pages/memberCenter/TicketSection.vue'; // 票券專區
//*** SerialNumber ***//
import SerialNumber from '@/pages/memberCenter/SerialNumber.vue'; // 序號兌換主頁
import SerialNumberDefault from '@/pages/memberCenter/SerialNumberDefault.vue'; // 序號兌換
import AccountBinding from '@/pages/accountSetting/AccountBinding.vue'; //VR 序號票券綁定
import RegistrationBinding from '@/pages/accountSetting/RegistrationBinding.vue'; //VR 序號票券創建
//*** AccountSetting ***//
import MemberInformation from '@/pages/memberCenter/MemberInformation.vue'; //會員資料
import ResetPassword from '@/pages/memberCenter/ResetPassword.vue'; //重設密碼
// import MyProps from '@/pages/memberCenter/MyProps.vue'; // 我的道具

//--------------------- Event ---------------------//
import Event from './pages/Event.vue';
// import Illusionary from './pages/event/Illusionary.vue'; //鏡花水月
// import VDay from './pages/event/VDay.vue' //VDay
import MiruWonderland from './pages/event/MiruWonderland.vue'; //Annin Miru Wonderland

const routes = [
  {
    path: '/',
    redirect: `/${store.state.currentLanguage}`,
  },
  {
    path: '/:lang',
    component: RouterViewPage,
    redirect: '',
    children: [
      {
        path: '',
        name: 'home',
        component: Index,
        meta: {
          title: 'BEATDAY',
        },
      },
      {
        path: 'products',
        name: 'products',
        component: Products, // 產品
        meta: {
          zh_tw: { title: '產品 | BEATDAY' },
          en: { title: 'Products | BEATDAY' },
        },
      },
      {
        path: 'product-info/:id',
        name: 'product-info',
        component: ProductInfo, // 產品詳情
        meta: {
          zh_tw: { title: '產品詳情 | BEATDAY' },
          en: { title: 'Product Details | BEATDAY' },
        },
      },
      {
        path: 'cart',
        name: 'shopping-cart',
        component: ShoppingCart, // 產品詳情
        meta: {
          zh_tw: { title: '商品購物車 | BEATDAY' },
          en: { title: 'Cart | BEATDAY' },
        },
      },
      {
        path: 'order-complete',
        name: 'order-complete',
        component: OrderComplete,
        meta: {
          zh_tw: { title: '完成訂單 | BEATDAY' },
          en: { title: 'OrderComplete | BEATDAY' },
        },
      },
      {
        path: 'faq',
        name: 'faq',
        component: FAQ,
        redirect: { name: 'account-issues' },
        children: [
          {
            path: 'account-issues',
            name: 'account-issues',
            component: AccountIssues,
            meta: { title: '帳號問題 | BEATDAY' },
          },

          {
            path: 'ticket-issues',
            name: 'ticket-issues',
            component: TicketIssues,
            meta: { title: '票務問題 | BEATDAY' },
          },
          {
            path: 'redemption-guide',
            name: 'redemption-guide',
            component: RedemptionGuide,
            meta: { title: '兌票教學 | BEATDAY' },
          },
          {
            path: 'beatday-usage',
            name: 'beatday-usage',
            component: BeatdayUsage,
            meta: { title: 'BEATDAY 使用 | BEATDAY' },
          },
        ],
      },
      {
        path: 'aboutUs',
        name: 'about-us',
        component: AboutUs,
        redirect: { name: 'about-beatday' },
        children: [
          {
            path: 'about-beatday',
            name: 'about-beatday',
            component: AboutBeatday,
            meta: { title: '關於BEATDAY | BEATDAY' },
          },

          {
            path: 'business-cooperation',
            name: 'business-cooperation',
            component: BusinessCooperation,
            meta: { title: '商務合作 | BEATDAY' },
          },
          {
            path: 'contact-us',
            name: 'contact-us',
            component: ContactUs,
            meta: { title: '聯繫我們 | BEATDAY' },
          },
        ],
      },
      {
        path: 'downloads',
        name: 'downloads',
        component: DownloadPage,
        meta: { title: '程式下載 | BEATDAY' },
      },
      {
        path: 'legal',
        name: 'legal',
        component: Legal,
        children: [
          {
            path: 'privacy',
            name: 'privacy',
            component: Privacy,
            meta: { title: '隱私權政策 | BEATDAY' },
          },
          {
            path: 'user-policy',
            name: 'user-policy',
            component: UserPolicy,
            meta: { title: '使用條款 | BEATDAY' },
          },
          {
            path: 'refund-policy',
            name: 'refund-policy',
            component: RefundPolicy,
            meta: { title: '退換票規範 | BEATDAY' },
          },
        ],
      },
      {
        path: 'return-policy-MizukiLin',
        name: 'return-policy-MizukiLin',
        component: ReturnPolicyMizukiLin,
        meta: { title: '林襄沉浸式寫真 | BEATDAY' },
      },
      {
        path: 'return-policy-VDay',
        name: 'return-policy-VDay',
        component: ReturnPolicy_VDay,
        meta: { title: '2024漫博 VDay誕生日 | BEATDAY' },
      },
      {
        path: 'return-policy-MiruWonderland',
        name: 'return-policy-MiruWonderland',
        component: ReturnPolicy_MiruWonderland,
        meta: { title: '杏仁ミル 週末 Party Night | BEATDAY' },
      },
      {
        path: 'member-login',
        name: 'member-login',
        component: MemberLogin,
        children: [
          {
            path: 'login',
            name: 'login',
            component: Login,
            meta: { title: '會員登入 | BEATDAY' },
          },
          {
            path: 'register',
            name: 'register',
            component: Register,
            meta: { title: '會員註冊 | BEATDAY' },
          },
          {
            path: 'forgot-password',
            name: 'forgot-password',
            component: ForgotPassword,
            meta: { title: '忘記密碼 | BEATDAY' },
          },
        ],
      },
      {
        path: 'register-memberInfo',
        name: 'register-memberInfo',
        component: RegisterMemberInfo,
        meta: { title: '會員資料註冊 | BEATDAY' },
      },
      {
        path: 'member',
        name: 'member',
        component: Member,
        redirect: { name: 'member-information' },
        children: [
          {
            path: 'ticket-section',
            name: 'ticket-section',
            component: TicketSection,
            meta: { title: '票券專區 | BEATDAY' },
          },
          // {
          //   path: 'my-props',
          //   name: 'my-props',
          //   component: MyProps,
          //   meta: { title: '我的道具 | BEATDAY' },
          // },
          {
            path: 'serial-number',
            name: 'serial-number',
            component: SerialNumber,
            redirect: { name: 'serial-number-default' },
            children: [
              {
                path: 'serial-number-default', // 空路径表示默认子路由
                name: 'serial-number-default',
                component: SerialNumberDefault,
                meta: { title: '序號兌換 | BEATDAY' },
              },
              {
                path: 'account-binding',
                name: 'account-binding',
                component: AccountBinding,
                meta: { title: '綁定 | BEATDAY' },
              },
              {
                path: 'registration-binding',
                name: 'registration-binding',
                component: RegistrationBinding,
                meta: { title: '創建 | BEATDAY' },
              },
            ],
          },
          {
            path: 'member-information',
            name: 'member-information',
            component: MemberInformation,
            meta: { title: '會員資料 | BEATDAY' },
          },
          {
            path: 'reset-password',
            name: 'reset-password',
            component: ResetPassword,
            meta: { title: '重設密碼 | BEATDAY' },
          },
        ],
      },
      {
        path: 'event',
        name: 'event',
        component: Event,
        children: [
          {
            path: 'AMW%3C3',
            name: 'AMW%3C3',
            component: MiruWonderland,
            meta: {
              zh_tw: {
                title: '杏仁ミル 週末 Party Night！ | BEATDAY',
              },
              en: {
                title: '杏仁ミル 週末 Party Night！| BEATDAY',
              },
              ja: {
                title: '杏仁ミル 週末 Party Night！ | BEATDAY',
              },
            },
          },
          // {
          //   path: 'illusionary',
          //   name: 'illusionary',
          //   component: Illusionary,
          //   meta: { title: '鏡花水月 | BEATDAY' },
          // },
        ],
      },
      // {
      //   path: 'maintenance',
      //   name: 'maintenance',
      //   component: Maintenance,
      //   meta: { title: 'Maintenance | BEATDAY' },
      // },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/zh_tw',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const newLang = to.params.lang;
  const oldLang = from.params.lang;

  const currentLanguage = store.state.currentLanguage; // 獲取當前語言

  if (to.meta && to.meta[currentLanguage] && to.meta[currentLanguage].title) {
    document.title = to.meta[currentLanguage].title;
  } else {
    document.title = 'BEATDAY';
  }

  if (newLang !== oldLang) {
    const lang = await loadLanguageAsync(newLang);
    await store.dispatch('setLanguage', lang);
  } else {
    await loadLanguageAsync(newLang);
  }

  next();
});

router.afterEach((to, from) => {
  const newLang = to.params.lang;
  const oldLang = from.params.lang;

  if (oldLang && newLang !== oldLang) {
    window.location.reload();
  }
});

export default router;
