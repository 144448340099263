const KEY = 'NEXT_PATH';

function set(path) {
  localStorage.setItem(KEY, path);
}

function get() {
  return localStorage.getItem(KEY);
}

function remove() {
  localStorage.removeItem(KEY);
}

export { set, get, remove };
