const KEY = 'LOGIN_METHOD';

function set(value) {
  localStorage.setItem(KEY, value);
}

function get() {
  return Number(localStorage.getItem(KEY));
}

function remove() {
  localStorage.removeItem(KEY);
}

export { set, get, remove };
