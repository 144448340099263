<template>
  <app-header></app-header>
  <section class="privacy_page return-policy-MizukiLin wrapper">
    <div class="wpb_wrapper">
      <div class="wpb_wrapper">
        <h1>【林襄沉浸式寫真】</h1>
        <h2>產品退貨規則</h2>
        <ul>
          <li>本產品藉由虛擬序號兌換，每組虛擬序號僅能開通一個帳號權限，虛擬序號兌換後會綁定 BEATDAY 帳號，並無法轉移或贈與至其他帳號。</li>
          <li>本產品附實體物品且現場販售者，不提供本產品猶豫期間及/或試用期間；本產品數位內容部分，無論是否為現場販售，均不提供產品猶豫期間及/或試用期間。</li>
          <li>
            消費者如欲申請退貨者，如有附實體物品之本產品(以非現場購買為限)，應於購買後7日內申請退貨，退貨的本產品必須是全新狀態且內容物不可分開退貨。請注意，本產品一經拆封即將失去退貨權利，請保持本產品全新狀態並保留原包裝及所有內附的贈品或說明書，避免影響後續退貨資格；購買虛擬序號者(未附實體物品)，限於本產品虛擬序號尚未兌換為限，並請於收到本產品序號7日內申請退貨，虛擬序號一經兌換或收到虛擬序號逾7日未辦理退貨者，恕不接受退貨。
          </li>
          <li>本產品退貨事宜請使用BEATDAY LINE 與客服人員聯繫辦理，本公司將盡快於上班日回覆。</li>
          <li>消費者凡申請退貨者，已使用之優惠券概不退還。</li>
        </ul>
        <h2>產品聲明</h2>
        <p>禁止公播、禁止商業播映聲明：</p>
        <p>
          一、本應用程式或網站內所有資料之所有權與智慧財產權，包括內容、文字、圖片、聲音、影像、軟體等均屬於「維特人股份有限公司」所有或經相關權利人授權合法使用，均受到中華民國著作權法、國際著作權條約及各國智慧財產權相關法律之保障。
        </p>
        <p>
          二、本內容僅供您個人觀看體驗，嚴禁將全部或部份內容透過第三方軟體下載、複製、重製、翻拍、修改、編輯、公開傳輸、公開播送、公開上映、或轉載於任何形式之平台或媒體之任何商業及非商業使用，否則將追究所有相關法律責任，絕不寬貸。
        </p>
        <p>【林襄沉浸式寫真】觀看期限：</p>
        <p>
          您清楚並同意本產品許可體驗觀看期限原則為上架後起算三年，除維特人或第三方支付服務商的政策或適用法律另有明確規定外，維特人保留本產品內容或觀看期限之變更權或解釋權，維特人有權在不對您承擔任何責任之狀況下，不經通知，隨時提供、修改、刪除及/或終止本產品的全部或任何部分；且不論期限是否到期或提前終止，一律不接受任何形式的退換及退款。
        </p>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</template>
<script>
// import { getSomething } from '@/api/apiService';
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
export default {
  name: 'LegalPage',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
  },
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {
    // async fetchData() {
    //     try {
    //         const response = await getSomething();
    //         this.test = response;
    //     } catch (error) {
    //         // Handle error
    //     }
    // },
  },
};
</script>

<style lang="scss"></style>
