<template>
  <aside>
    <h1>{{ $t('common.member_nav_key007') }}</h1>
    <nav>
      <ul>
        <li class="accountSetting">
          <h3>
            {{ $t('common.member_nav_key003') }}
          </h3>
          <ul>
            <li>
              <router-link :to="`/${currentLanguage}/member/member-information`" exact active-class="active">
                {{ $t('common.member_nav_key004') }}
              </router-link>
            </li>
            <li v-show="this.loginMethod === 1">
              <router-link :to="`/${currentLanguage}/member/reset-password`" active-class="active">
                {{ $t('common.member_nav_key005') }}
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/member/ticket-section`" exact active-class="active">
            {{ $t('common.member_nav_key001') }}
          </router-link>
        </li>
        <li>
          <router-link :to="`/${currentLanguage}/member/serial-number/serial-number-default`" active-class="active">
            {{ $t('common.member_nav_key002') }}
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavAside',
  computed: {
    ...mapState(['loginMethod']),
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss"></style>
