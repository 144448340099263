<template>
  <div></div>
</template>

<script setup>
import { defineEmits, defineExpose } from 'vue';
import { imageUploader } from '@/utils/imageUploader';

const emit = defineEmits(['upload-success', 'upload-error']);

const uploadS3Image = async (file, uploadUrl) => {
  if (!file) {
    emit('upload-error', 'No file provided');
    return;
  }

  try {
    const fileUrl = await imageUploader.s3Uploader.uploadToS3(file, uploadUrl);
    emit('upload-success', fileUrl);
  } catch (error) {
    emit('upload-error', error);
  }
};

const uploadImage = async (file, uploadUrl) => {
  if (!file) {
    emit('upload-error', 'No file provided');
    return;
  }

  try {
    const fileUrl = await imageUploader.uploadImage(file, uploadUrl);
    emit('upload-success', fileUrl);
  } catch (error) {
    emit('upload-error', error);
  }
};

// Expose methods to parent
defineExpose({ uploadImage, uploadS3Image });
</script>
