<template>
  <section class="wrapper">
    <div class="outline">
      <div class="title">{{ $t('shopping-cart.key001') }}</div>
      <section class="section01 products_list">
        <div class="cartItem" v-for="(product, index) in cartData.orderItems" :key="product.id">
          <div class="products_item product_list_item">
            <div class="left">
              <div class="item_img_box">
                <img :src="product.image" class="item_img" alt="" />
              </div>
              <div class="item_content">
                <div class="direction">
                  <router-link :to="`/${currentLanguage}/product-info/${product.productId}`" target="_blank" class="item_name">
                    {{ product.productName }}
                  </router-link>
                  <div class="item_dateTime">
                    {{ product.packTitle }}
                  </div>
                  <div class="item_dateTime" v-html="product.time"></div>
                </div>
                <div class="item_price">
                  <span class="current_price">NT$ {{ formatNumber(product.price) }}</span>
                  <span class="original_price" v-if="product.originalPrice"> NT$ {{ formatNumber(product.originalPrice) }} </span>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="chooseCount">
                <img src="../../assets/img/products/minus.svg" @click="decreaseTicketCount(index)" alt="" />
                <div class="count">{{ product.quantity }}</div>
                <img src="../../assets/img/products/plus.svg" @click="increaseTicketCount(index)" alt="" />
                <div class="count_alert">
                  {{ product.ticketAlert }}
                </div>
              </div>
              <p class="delete" @click="deleteProduct(index)">
                {{ $t('shopping-cart.key002') }}
              </p>
            </div>
          </div>
          <div class="product products_item add_item" v-for="(item, addOnIndex) in product.addOnItems" :key="addOnIndex">
            <div class="left">
              <div class="item_img_box">
                <img :src="item.image" class="product-image" alt="product image" />
              </div>
              <div class="product-info item_content">
                <div class="direction">
                  <p class="add_announce">{{ $t('shopping-cart.key042') }}</p>
                  <div class="item_name">
                    {{ item.title }}
                  </div>
                  <div class="item_dateTime">
                    {{ item.description }}
                  </div>
                </div>
                <div class="item_price">
                  <span class="current_price">NT$ {{ formatNumber(item.price) }}</span>
                </div>
              </div>
            </div>

            <div class="right">
              <div class="chooseCount">
                <div class="count">X {{ item.quantity }}</div>
                <p class="delete" @click="deleteAddProduct(index, addOnIndex)">
                  {{ $t('shopping-cart.key002') }}
                </p>
              </div>
            </div>
          </div>
          <add-on-products ref="addOnProducts" :product="product" :cartItemIndex="index" @add-to-cart="handleAddToCart" v-if="product.addOnItemGroups.length > 0"></add-on-products>
        </div>
      </section>

      <div class="divide"></div>
      <section class="summaryCharges">
        <div class="total_amount div_style">
          <span class="label">{{ $t('shopping-cart.key004') }}</span>
          <span class="value">NT$ {{ formatNumber(cartData.totalAmount) }}</span>
        </div>
        <div class="promotional_discount div_style">
          <span class="label">{{ $t('shopping-cart.key005') }}</span>
          <div class="value discount_value">-NT$ {{ formatNumber(cartData.totalDiscount) }}</div>
        </div>
        <!-- <div class="coupon_discount div_style">
          <span class="label">優惠券</span>
          <span class="value discount_value">-NT$ 30</span>
        </div>
        <div class="coupon_name div_style">
          【鏡花水月】商品折底 30 元優惠券
          <i class="bi bi-x"></i>
        </div> -->
        <div class="alert_box" v-show="cartData.hasPhysicalItem">
          {{ $t('shopping-cart.event_key001') }}
          <br />
          <p v-html="formattedEvent_key002"></p>
        </div>
        <div class="totalSubmit">
          <div>
            <span class="label">{{ $t('shopping-cart.key006') }}</span>
            <span class="value">NT$ {{ formatNumber(cartData.finalAmount) }}</span>
          </div>
          <button class="submit_order button_style_linear" @click="confirmOrder">
            {{ $t('shopping-cart.key007') }}
          </button>
        </div>
      </section>
    </div>
  </section>
  <section class="member-login cart_login" v-show="showLoginModal">
    <div class="mask" @click="close_login"></div>
    <div class="login_con">
      <login :guest_purchase="true" nextPath="cart" @next="nextStep"></login>
    </div>
  </section>
</template>

<script>
import { setCartItems, getCartItems, setCartItemExpiry, getCartItemExpiry } from '@/localStorage/cartStorage.js';
import Login from '@/pages/login/Login.vue';
import AddOnProducts from '@/components/cartCheckout/AddOnProducts.vue';

export default {
  name: 'CartComponent',
  props: {
    cartData: Object,
    phoneServerCheck: {
      type: Boolean,
      default: false,
    },
    formatNumber: {
      type: Function,
      required: true,
    },
  },
  emits: ['update-data', 'submit-order', 'next', 'prev'],
  components: {
    login: Login,
    'add-on-products': AddOnProducts,
  },
  provide() {
    return {
      isListeningNext: true,
    };
  },
  data() {
    return {
      showLoginModal: false,
    };
  },
  computed: {
    jwt() {
      return this.$store.state.jwt;
    },
    formattedEvent_key002() {
      return this.$t('shopping-cart.event_key002', {
        beatday_line: '<a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053" target="_blank">@beatday</a>',
        bratday_mail: '<a href="mailto:contact@beatday.com">contact@beatday.com</a>',
      });
    },
  },
  methods: {
    updateLocalStorage() {
      // Define expiration time (24 hours)
      const EXPIRY_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const now = new Date().getTime();

      let cartItems = getCartItems() || [];
      let expiry = getCartItemExpiry() || {};

      // Iterate through the cart list and update quantities in localStorage
      this.cartData.orderItems.forEach((item) => {
        const existingIndex = cartItems.findIndex((cartItem) => cartItem.productId === item.productId && cartItem.sessionId === item.sessionId && cartItem.packId === item.packId);

        if (existingIndex !== -1) {
          // 更新现有商品的数量
          cartItems[existingIndex].quantity = item.quantity;
        } else {
          // 添加新商品
          cartItems.push({
            productId: item.productId,
            sessionId: item.sessionId,
            packId: item.packId,
            quantity: item.quantity,
          });
        }

        expiry[item.productId + '-' + item.sessionId] = now + EXPIRY_DURATION;
      });

      // Save the updated cartItems back to localStorage
      setCartItems(cartItems);
      setCartItemExpiry(expiry);
    },
    updateLocalStorageAfterDelete(removedItem) {
      const cartItems = getCartItems() || [];

      // 找到並刪除 localStorage 中的對應項目
      const updatedCartItems = cartItems.filter(
        (item) => !(item.productId === removedItem.productId && item.sessionId === removedItem.sessionId && item.packId === removedItem.packId),
      );

      // 更新 localStorage
      setCartItems(updatedCartItems);
    },
    //購物車是否有商品
    checkCartEmpty() {
      if (this.cartData.orderItems.length === 0) {
        alert(this.$t('shopping-cart.key020'));
        this.$router.push(`/${this.currentLanguage}/`);
      }
    },
    //商品數量 > 加減 & 刪除
    increaseTicketCount(index) {
      const item = this.cartData.orderItems[index];
      if (item.quantity < 10) {
        item.quantity++;
        this.$emit('update-data', this.cartData);
        this.updateLocalStorage();
        if (this.$refs.addOnProducts) {
          this.$refs.addOnProducts.forEach((ref) => {
            if (ref && typeof ref.calaMaxQuantity === 'function') {
              ref.calaMaxQuantity();
            }
          });
        }
      } else {
        item.ticketAlert = this.$t('shopping-cart.key021');
      }
    },
    decreaseTicketCount(index) {
      const item = this.cartData.orderItems[index];
      const product = this.cartData.orderItems;
      if (item.quantity > 1) {
        item.ticketAlert = '';
        item.quantity--;
        this.$emit('update-data', this.cartData);
        this.updateLocalStorage();
        if (this.$refs.addOnProducts) {
          this.$refs.addOnProducts.forEach((ref) => {
            if (ref && typeof ref.checkQuantity === 'function') {
              ref.checkQuantity();
            }
          });
        }
      } else {
        if (confirm(this.$t('shopping-cart.key022'))) {
          setTimeout(() => {
            const removedItem = product.splice(index, 1)[0];
            this.updateLocalStorageAfterDelete(removedItem);
            this.$emit('update-data', this.cartData);
            this.checkCartEmpty();
          }, 500);
        }
      }
      this.updateLocalStorage();
    },
    deleteProduct(index) {
      const product = this.cartData.orderItems;

      if (confirm(this.$t('shopping-cart.key022'))) {
        setTimeout(() => {
          const removedItem = product.splice(index, 1)[0];
          this.updateLocalStorageAfterDelete(removedItem);
          this.$emit('update-data', this.cartData);
          this.checkCartEmpty();
        }, 1000);
      }
    },
    addToCart(product, index) {
      const orderItems = this.cartData.orderItems;
      const productWithCount = {
        ...product,
        quantity: 1,
      };
      orderItems.push(productWithCount);
      this.$emit('update-data', this.cartData);
      this.addOnProducts.splice(index, 1);
    },
    confirmOrder() {
      if (this.jwt != null) {
        this.nextStep();
      } else {
        document.body.style.overflow = 'hidden';
        this.showLoginModal = true;
      }
    },
    nextStep() {
      this.$emit('update-data', this.cartData);
      this.$emit('next');
      document.body.style.overflow = '';
      this.showLoginModal = false;
    },
    close_login() {
      document.body.style.overflow = '';
      this.showLoginModal = false;
    },
    // 加購商品加入購物車
    handleAddToCart({ cartItemIndex, addOnItemsToAdd }) {
      const existingItem = this.cartData.orderItems[cartItemIndex];
      existingItem.addOnItems = JSON.parse(JSON.stringify(addOnItemsToAdd));
      this.$emit('update-data', this.cartData);
    },
    deleteAddProduct(productIndex, addOnIndex) {
      const product = this.cartData.orderItems[productIndex];

      if (product.addOnItems && addOnIndex >= 0 && addOnIndex < product.addOnItems.length) {
        product.addOnItems.splice(addOnIndex, 1);
      }
    },
  },
};
</script>

<style lang="scss"></style>
