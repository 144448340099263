const KEY = 'Current_Language';

function set(language) {
  localStorage.setItem(KEY, language);
}

function get() {
  return localStorage.getItem(KEY);
}

export { set, get };
