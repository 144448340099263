import axios from './axios';

const getProducts = (currentLanguage) => {
  return axios.get('/api/store/products', {
    headers: {
      'Accept-Language': currentLanguage,
    },
  });
};

const getProductInfo = (productId, currentLanguage) => {
  return axios.get(`/api/store/products/${productId}`, {
    headers: {
      'Accept-Language': currentLanguage,
    },
  });
};

const querySessionPacks = (cartItems, currentLanguage) => {
  return axios.post(
    '/api/store/products/querySessionPacks',
    {
      query: cartItems,
    },
    {
      headers: {
        'Accept-Language': currentLanguage,
      },
    },
  );
};

const createOrder = (orderItems, currentLanguage) => {
  return axios.post('/api/store/orders', orderItems, {
    headers: {
      'Accept-Language': currentLanguage, //zh-tw
    },
  });
};

const createAuthOrder = (orderItems, currentLanguage, jwt) => {
  return axios.post('/api/store/user/orders', orderItems, {
    headers: {
      'Accept-Language': currentLanguage, //zh-tw
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export { getProducts, getProductInfo, querySessionPacks, createOrder, createAuthOrder };
