// 定义常量
const CART_ITEMS_KEY = 'cartItems';
const CART_EXPIRY_KEY = 'cartExpiry';

// 设置 localStorage 的值
function setCartItems(value) {
  localStorage.setItem(CART_ITEMS_KEY, JSON.stringify(value));

  window.dispatchEvent(new Event('carItemsChanged'));
}

// 获取 localStorage 的值
function getCartItems() {
  let cartItems = localStorage.getItem(CART_ITEMS_KEY);

  if (!cartItems) {
    return null;
  }
  let parsedCartItems = JSON.parse(cartItems);

  const expiry = getCartItemExpiry() || {};
  // remove expired items
  const now = new Date().getTime();
  const expiredKeys = Object.keys(expiry).filter((key) => now > expiry[key]);

  if (expiredKeys.length > 0) {
    parsedCartItems = parsedCartItems.filter((item) => !expiredKeys.includes(item.productId + '-' + item.sessionId));
    expiredKeys.forEach((key) => {
      delete expiry[key];
    });

    // Update localStorage with the filtered items and expiry
    setCartItems(parsedCartItems);
    setCartItemExpiry(expiry);
  }

  return parsedCartItems;
}

// 删除 localStorage 的值
function removeCartItems() {
  localStorage.removeItem(CART_ITEMS_KEY);

  window.dispatchEvent(new Event('carItemsChanged'));
}

// 设置 localStorage 的值
function setCartItemExpiry(value) {
  localStorage.setItem(CART_EXPIRY_KEY, JSON.stringify(value));
}

// 获取 localStorage 的值
function getCartItemExpiry() {
  const value = localStorage.getItem(CART_EXPIRY_KEY);
  return value ? JSON.parse(value) : null;
}

// 删除 localStorage 的值
function removeCartItemExpiry() {
  localStorage.removeItem(CART_EXPIRY_KEY);
}

// 模块导出接口
export { setCartItems, getCartItems, removeCartItems, setCartItemExpiry, getCartItemExpiry, removeCartItemExpiry };
