<template>
  <section class="registration-binding account-setting">
    <h1>{{ $t('serial-number.registration-binding_key001') }}</h1>
    <div class="filter-section">
      <router-link :to="`/${currentLanguage}/member/serial-number/account-binding`">
        <button class="filter-button">
          {{ $t('serial-number.registration-binding_key002') }}
        </button>
      </router-link>
      <router-link to="">
        <button class="filter-button active">
          {{ $t('serial-number.registration-binding_key003') }}
        </button>
      </router-link>
    </div>

    <form class="login_box" v-if="showInputForm" @submit.prevent="login">
      <label class="">
        <span class="title">{{ $t('serial-number.registration-binding_key004') }}</span>
        <input type="text" v-model="username" :placeholder="$t('serial-number.registration-binding_key005')" name="username" @input="handleInput" />
      </label>
      <label class="">
        <span class="title">{{ $t('serial-number.registration-binding_key006') }}</span>
        <div class="eye_fixed">
          <input :type="passwordVisible ? 'text' : 'password'" v-model="password" :placeholder="$t('serial-number.registration-binding_key007')" name="passwordNew" />
          <div class="eye_btn" @click="togglePasswordVisibility('new')">
            <i class="bi" :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i>
          </div>
        </div>
      </label>
      <label class="">
        <span class="title">{{ $t('serial-number.registration-binding_key008') }}</span>
        <div class="eye_fixed">
          <input :type="passwordVisible ? 'text' : 'password'" v-model="confirmPassword" :placeholder="$t('serial-number.registration-binding_key009')" name="confirmPassword" />
          <div class="eye_btn" @click="togglePasswordVisibility('new')">
            <i class="bi" :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i>
          </div>
        </div>
      </label>

      <div class="alert">{{ alert }}</div>
      <button type="submit" class="submit-button">
        {{ $t('serial-number.registration-binding_key010') }}
      </button>
    </form>

    <form v-if="showVerifyNumber" @submit.prevent="verifyCode">
      <div class="verify">
        <label for="">
          <input v-model="verificationCode" type="verify" id="verify" name="verify" :placeholder="$t('serial-number.registration-binding_key011')" />
        </label>
      </div>

      <div class="alert">{{ alert }}</div>
      <button type="submit" class="submit-button prev" @click="prev">
        {{ $t('serial-number.registration-binding_key012') }}
      </button>
      <button type="submit" class="submit-button">
        {{ $t('serial-number.registration-binding_key013') }}
      </button>
    </form>

    <div v-if="registrationSuccess" class="registration-success">
      <div class="flex">
        <h2 class="title">
          {{ $t('serial-number.registration-binding_key014') }}
        </h2>
        <dotlottie-player
          id="check-box"
          src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
          background="transparent"
          speed="1"
          autoplay=""
        ></dotlottie-player>
      </div>
      <p>
        {{
          $t('serial-number.registration-binding_key015', {
            countdownToHome,
          })
        }}
        <router-link to="/member/serial-number/serial-number-default">
          {{ $t('serial-number.registration-binding_key016') }}
        </router-link>
      </p>
    </div>
  </section>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { sendVerification, signUp, bindAccount, resendVerification, basicLogin } from '@/api/apiService';
import Loading from '@/components/Loading.vue';

export default {
  name: 'AccountBinding',
  components: {
    Loading,
  },
  computed: {
    jwt() {
      return this.$store.state.jwt;
    },
  },
  data() {
    return {
      username: '',
      password: '',
      confirmPassword: '',
      alert: '',
      showInputForm: true,
      showVerifyNumber: false,
      registrationSuccess: false,
      passwordVisible: false,
      emailCheck: false,
      countdownToHome: 5,
      verificationCode: '',
      loading: false,
    };
  },
  methods: {
    // 禁止輸入空白鍵
    handleInput() {
      const inputEmail = this.username;
      this.username = inputEmail.trim();

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(this.username)) {
        this.showVerifyNumber = false;
        this.emailCheck = true;
      } else {
        this.emailCheck = false;
      }
    },
    async login() {
      if (!this.emailCheck) {
        this.alert = this.$t('serial-number.registration-binding_key017');
        return;
      }

      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
      if (!passwordRegex.test(this.password)) {
        this.alert = this.$t('serial-number.registration-binding_key018');
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.alert = this.$t('serial-number.registration-binding_key019');
        return;
      } else {
        try {
          this.loading = true;
          await sendVerification({ account: this.username });
          this.showInputForm = false;
          this.showVerifyNumber = true;
          this.alert = '';
        } catch (error) {
          if (error?.response?.status === this.$statusCodes.Unauthorized) {
            this.alert = this.$t('register.key031');
          } else if (error?.response?.status === this.$statusCodes.Forbidden) {
            try {
              await resendVerification({
                account: this.username,
              });
              this.showInputForm = false;
              this.showVerifyNumber = true;
              this.alert = '';
            } catch (error) {
              console.error(error);
              this.alert = this.$t('serial-number.registration-binding_key020');
            }
          } else {
            console.error(error);
            this.alert = this.$t('serial-number.registration-binding_key020');
          }
        } finally {
          this.loading = false;
        }
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async verifyCode() {
      try {
        this.loading = true;
        if (this.showVerifyNumber === true) {
          await signUp({
            account: this.username,
            password: this.password,
            code: this.verificationCode,
          });

          await bindAccount(this.username, this.jwt);

          const loginData = {
            account: this.username,
            password: this.password,
          };
          await basicLogin(loginData);

          this.alert = '';
          this.playAnimation();
          this.showVerifyNumber = false;
          this.registrationSuccess = true;

          const countdownInterval = setInterval(() => {
            if (this.countdownToHome > 0) {
              this.countdownToHome--;
            } else {
              clearInterval(countdownInterval);
              this.$router.push(`/${this.currentLanguage}/member/serial-number`);
            }
          }, 1000);
        }
      } catch (error) {
        this.alert = this.$t('serial-number.registration-binding_key021');
      } finally {
        this.loading = false;
      }
    },
    prev() {
      this.showInputForm = true;
      this.showVerifyNumber = false;
      this.alert = '';
    },
    playAnimation() {
      this.$nextTick(() => {
        const checkBox = document.querySelector('#check-box');
        if (checkBox) {
          setInterval(() => {
            checkBox.play();
          }, 0);
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
