import axios from 'axios';
import store from '../vuex';
import router from '../router';

const APIBaseURL = process.env.API_DOMAIN;

const instance = axios.create({
  baseURL: APIBaseURL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([401].includes(error.response.status)) {
      // Handle 401 error
      store.dispatch('logout');
      router.push(`/${store.state.currentLanguage}/member-login/login`);
    }

    return Promise.reject(error);
  },
);

export default instance;
