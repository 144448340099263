<template>
  <div class="other">
    <h3 class="p_Login">{{ $t('common.ThirdPartyLogin_key001') }}</h3>

    <button @click="loginWithProvider('Facebook')" class="btn btn_bg_02">
      {{ $t('common.ThirdPartyLogin_key002') }}
    </button>
    <button @click="loginWithProvider('Google')" class="btn">
      {{ $t('common.ThirdPartyLogin_key003') }}
    </button>

    <button @click="loginWithProvider('Apple')" class="btn btn_bg_03">
      {{ $t('common.ThirdPartyLogin_key004') }}
    </button>
    <button @click="loginWithProvider('Discord')" class="btn btn_bg_04">
      {{ $t('common.ThirdPartyLogin_key005') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'loginRegion',
  components: {},
  mounted() {},
  data() {
    return {
      jwt: '',
      username: '',
      password: '',
      verification: '', // 驗證碼
      passwordVisible: false,
      alert: '',
    };
  },
  methods: {
    loginWithProvider(provider) {
      this.alert = '';
      let routePath = '';
      if (provider === 'Google') {
        routePath = process.env.VUE_APP_GOOGLE_AUTH_URL;
      } else if (provider === 'Facebook') {
        routePath = process.env.VUE_APP_FACEBOOK_AUTH_URL;
      } else if (provider === 'Apple') {
        routePath = process.env.VUE_APP_APPLE_AUTH_URL;
      } else if (provider === 'Discord') {
        routePath = process.env.VUE_APP_DISCORD_AUTH_URL;
      }

      window.location.replace(routePath);
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/pages/login.scss';
</style>
