<template>
  <div class="loginRegion">
    <h1 class="title">{{ $t('login.key001') }}</h1>
    <form @submit.prevent="login">
      <div class="username">
        <i class="bi bi-person-fill"></i>
        <input v-model="username" type="text" id="username" name="username" :placeholder="$t('login.key002')" />
      </div>

      <div class="password">
        <i class="bi bi-key-fill"></i>
        <input v-model="password" :type="passwordVisible ? 'text' : 'password'" id="password" name="password" :placeholder="$t('login.key003')" />
        <div class="eye_btn" @click="togglePasswordVisibility">
          <i class="bi" :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i>
        </div>
      </div>

      <div class="alert" ref="message">{{ alert }}</div>
      <button type="submit" class="login_btn">
        {{ $t('login.key004') }}
      </button>

      <div class="link">
        <router-link :to="`/${currentLanguage}/member-login/forgot-password`">
          {{ $t('login.key005') }}
        </router-link>
        <router-link :to="`/${currentLanguage}/member-login/register`">
          {{ $t('login.key006') }}
        </router-link>
      </div>
    </form>
    <third-party></third-party>
    <button @click="nextStep" class="guest_purchase" v-show="guest_purchase">
      {{ $t('login.key009') }}
    </button>
  </div>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { basicLogin, getUserInfo } from '@/api/apiService';
import Loading from '@/components/Loading.vue';
import ThirdParty from '@/components/login/ThirdPartyLogin.vue';
import { set as setNextPath, remove as removeNextPath } from '@/localStorage/nextPath';

export default {
  name: 'loginRegion',
  emits: ['next'],
  props: {
    guest_purchase: {
      type: Boolean,
      required: false,
    },
    // set next path for third party login
    nextPath: {
      type: String,
      required: false,
    },
  },
  components: {
    Loading,
    'third-party': ThirdParty,
  },
  // Inject the provided value
  inject: {
    isListeningNext: {
      default: false,
    },
  },
  mounted() {
    if (this.nextPath != null) {
      setNextPath(this.nextPath);
    } else {
      removeNextPath();
    }
  },
  computed: {
    jwt() {
      return this.$store.state.jwt;
    },
  },
  data() {
    return {
      username: '',
      password: '',
      verification: '', // 驗證碼
      passwordVisible: false,
      alert: '',
      loading: false,
      local_guest_purchase: this.guest_purchase,
    };
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        // 1. 檢查帳號密碼是否有資料
        if (!this.username || !this.password) {
          this.alert = this.$t('login.key007');
          this.shakeMessage();
          return;
        }
        this.alert = ''; // 清空警告
        const loginData = {
          account: this.username,
          password: this.password,
        };
        const jwt = await basicLogin(loginData);

        // for cart page
        if (this.isListeningNext) {
          this.$emit('next');
          return;
        }

        const userData = await getUserInfo(jwt, true);

        if (!userData) return;

        this.$router.push(`/${this.currentLanguage}/`);
      } catch (error) {
        this.alert = this.$t('login.key008');
        this.shakeMessage();
      } finally {
        this.loading = false;
      }
    },

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },

    shakeMessage() {
      if (this.alert) {
        const message = this.$refs.message;
        message.style.animationName = 'none';

        requestAnimationFrame(() => {
          message.style.animationName = 'shakeOpacity';
        });
      }
    },
    nextStep() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss"></style>
