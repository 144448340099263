<template>
  <section class="wrapper download_page faq">
    <h1>{{ $t('faq.key003') }}</h1>
    <div class="block">
      <div class="faq-item" v-for="(faq, index) in faqs_download" :key="'download_' + index">
        <div class="faq-question" @click="toggleAnswer('download_' + index)" :style="{ color: faq.open ? 'white' : '#A6BED9' }">
          <i class="bi bi-caret-right-fill faq-icon" :class="{ open: faq.open }"></i>
          {{ faq.question }}
        </div>
        <div class="faq-answer" v-if="faq.open" v-html="faq.answer"></div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'FAQ',
  components: {},
  mounted() {},
  data() {
    return {
      faqs_download: [
        {
          question: this.$t('ticket-issues.key001'),
          answer: this.$t('ticket-issues.key002'),
          open: false,
        },
        {
          question: this.$t('ticket-issues.key003'),
          answer: this.$t('ticket-issues.key004'),
          open: false,
        },
        {
          question: this.$t('ticket-issues.key005'),
          answer: this.$t('ticket-issues.key006'),
          open: false,
        },
      ],
    };
  },
  props: {},
  computed: {},
  methods: {
    toggleAnswer: function (key) {
      const targetElement = document.querySelector(`[key="${key}"]`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
      this.faqs_download.forEach((faq, index) => {
        if ('download_' + index === key) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
