<template>
  <section class="wrapper payment">
    <div class="outline">
      <div class="title">{{ $t('shopping-cart.key008') }}</div>
      <section class="section01 products_list">
        <div class="accordion-item" :class="{ active: activeAccordion === 0 }">
          <div class="accordion-heading" @click="toggleAccordion(0)">
            {{ $t('shopping-cart.key009') }}
          </div>
          <div class="accordion-content">
            <section class="section01 products_list">
              <div class="cartItem" v-for="product in cartData.orderItems" :key="product.id">
                <div class="products_item">
                  <div class="left">
                    <div class="item_img_box">
                      <img :src="product.image" class="item_img" alt="" />
                    </div>
                    <div class="item_content">
                      <div class="direction">
                        <router-link :to="`/${currentLanguage}/product-info/${product.productId}`" target="_blank" class="item_name">
                          {{ product.productName }}
                        </router-link>
                        <div class="item_dateTime">
                          {{ product.packTitle }}
                        </div>
                        <div class="item_dateTime" v-html="product.time"></div>
                      </div>
                      <div class="item_price">
                        <span class="current_price">NT$ {{ formatNumber(product.price) }}</span>
                        <span class="original_price" v-if="product.originalPrice"> NT$ {{ formatNumber(product.originalPrice) }} </span>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="chooseCount">
                      <div class="count">X {{ product.quantity }}</div>
                    </div>
                  </div>
                </div>
                <div class="product products_item add_item" v-for="(item, addOnIndex) in product.addOnItems" :key="addOnIndex">
                  <div class="left">
                    <div class="item_img_box">
                      <img :src="item.image" class="product-image" alt="product image" />
                    </div>
                    <div class="product-info item_content">
                      <div class="direction">
                        <p class="add_announce">{{ $t('shopping-cart.key042') }}</p>
                        <div class="item_name">
                          {{ item.title }}
                        </div>
                        <div class="item_dateTime">
                          {{ item.description }}
                        </div>
                      </div>
                      <div class="item_price">
                        <span class="current_price">NT$ {{ formatNumber(item.price) }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="right">
                    <div class="chooseCount">
                      <div class="count">X {{ item.quantity }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="divide"></div>
            <section class="summaryCharges">
              <div class="total_amount div_style">
                <span class="label">{{ $t('shopping-cart.key004') }}</span>
                <span class="value">NT$ {{ formatNumber(cartData.totalAmount) }}</span>
              </div>
              <div class="promotional_discount div_style">
                <span class="label">{{ $t('shopping-cart.key005') }}</span>
                <div class="value discount_value">-NT$ {{ formatNumber(cartData.totalDiscount) }}</div>
              </div>
              <div class="total_amount div_style">
                <span class="label">{{ $t('shopping-cart.key043') }}</span>
                <span class="value">NT$ {{ shippingValue }}</span>
              </div>
              <div class="totalSubmit">
                <button class="submit_order button_style_linear" @click="prevStep">
                  {{ $t('shopping-cart.key010') }}
                </button>
                <div class="div">
                  <span class="label">{{ $t('shopping-cart.key006') }}</span>
                  <span class="value">NT$ {{ formatNumber(cartData.finalAmount + shippingValue) }}</span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
    <section>
      <div class="outline orderContact">
        <div class="title">
          {{ $t('shopping-cart.key011') }}
          <span>&nbsp;&nbsp;&nbsp; {{ $t('shopping-cart.key012') }}</span>
        </div>
        <form>
          <div class="flex_box">
            <div class="form-group">
              <label for="name">
                <span>*</span>
                {{ $t('shopping-cart.key013') }}
              </label>
              <input type="text" class="name" v-model="localCartData.name" @input="validateName" @blur="validateNameOnBlur" required />
            </div>
            <div class="alert" v-if="nameAlert">
              {{ nameAlert }}
            </div>
          </div>
          <div class="flex_box">
            <div class="form-group">
              <label for="phone">
                <span v-if="localCartData.hasPhysicalItem">*</span>
                {{ $t('shopping-cart.key014') }}
              </label>
              <vue-tel-input
                v-model="localCartData.phone"
                :dropdownOptions="dropdownOptions"
                :inputOptions="inputOptions"
                @validate="phoneObject"
                mode="international"
                @blur="PhoneBlur"
              />
            </div>
            <div class="alert" v-if="phoneAlert">
              {{ phoneAlert }}
            </div>
          </div>
          <div class="flex_box">
            <div class="form-group email_box">
              <label for="email">
                <span>*</span>
                Email
              </label>
              <input type="email" class="email" v-model="localCartData.email" @input="removeSpecialCharsFromEmail" @blur="validateEmailOnBlur" required />
            </div>
            <div class="alert" v-if="emailAlert">
              {{ emailAlert }}
            </div>
            <div class="warning_text">
              {{ $t('shopping-cart.key015') }}
            </div>
          </div>
          <div class="flex_box" v-show="localCartData.hasPhysicalItem">
            <div class="form-group address_box">
              <label for="email">
                <span>*</span>
                {{ $t('shopping-cart.key025') }}
              </label>
              <div class="address">
                <div id="zipcode2"></div>
                <label class="addressDetail">
                  <input v-model="localCartData.address.addressDetail" :placeholder="$t('shopping-cart.key030')" @blur="updateAddressDetail" required />
                </label>
              </div>
            </div>
            <div class="alert" v-if="addressAlert">
              {{ addressAlert }}
            </div>
            <div class="warning_text">
              {{ $t('shopping-cart.key023') }}
            </div>
          </div>
          <div class="flex_box" v-show="localCartData.hasPhysicalItem">
            <div class="form-group email_box">
              <label for="email">
                <span>*</span>
                {{ $t('shopping-cart.key024') }}
              </label>
              <div class="shipping">
                <select v-model="localCartData.shipping" @change="updateShipping">
                  <option value="" disabled>
                    {{ $t('shopping-cart.key026') }}
                  </option>
                  <option v-for="option in shippingOptions" :key="option.value" :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="alert" v-if="shippingAlert">
              {{ shippingAlert }}
            </div>
          </div>
          <div class="flex_box">
            <div class="form-group">
              <label for="name" v-html="formatBreak($t('shopping-cart.key040'))"> </label>
              <input type="text" class="name" inputmode="numeric" pattern="[0-9]*" @input="onInputTaxId" v-model="localCartData.taxId" @blur="validateTaxId" required />
            </div>
            <div class="alert" v-if="taxIdAlert">
              {{ taxIdAlert }}
            </div>
          </div>
        </form>
      </div>
    </section>
    <section>
      <div class="outline payType">
        <div class="title">{{ $t('shopping-cart.key016') }}</div>
        <div class="radio-group">
          <input type="radio" id="credit_card" value="credit_card" checked />
          <label for="credit_card">{{ $t('shopping-cart.key017') }}</label>
        </div>
        <div class="legal">
          <input type="checkbox" id="terms" v-model="agreed" />
          <label for="terms">
            {{ $t('shopping-cart.key018') }}
          </label>
        </div>
        <p v-html="formattedMessage" class="legal_text"></p>
        <div class="alert_box" v-show="submitAlert">{{ submitAlert }}</div>
        <div class="divide"></div>
        <section class="summaryCharges">
          <div class="total_amount div_style">
            <span class="label">{{ $t('shopping-cart.key004') }}</span>
            <span class="value">NT$ {{ formatNumber(cartData.totalAmount) }}</span>
          </div>
          <div class="promotional_discount div_style">
            <span class="label">{{ $t('shopping-cart.key005') }}</span>
            <div class="value discount_value">-NT$ {{ formatNumber(cartData.totalDiscount) }}</div>
          </div>
          <div class="total_amount div_style">
            <span class="label">{{ $t('shopping-cart.key043') }}</span>
            <span class="value">NT$ {{ shippingValue }}</span>
          </div>
        </section>
        <div class="totalSubmit">
          <div class="div">
            <span class="label">{{ $t('shopping-cart.key006') }}</span>
            <span class="value">
              NT$
              {{ formatNumber(shippingValue + cartData.totalAmount - cartData.totalDiscount) }}
            </span>
          </div>
          <button class="submit_order button_style_linear" @click="proceedToPayment">
            {{ $t('shopping-cart.key019') }}
          </button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: 'PaymentComponent',
  props: {
    cartData: Object,
    phoneServerCheck: {
      type: Boolean,
      default: false,
    },
    formatNumber: {
      type: Function,
      required: true,
    },
  },
  components: {
    VueTelInput,
  },
  data() {
    return {
      localCartData: JSON.parse(JSON.stringify(this.cartData)),
      nameAlert: '',
      phoneAlert: '',
      emailAlert: '',
      addressAlert: '',
      shippingAlert: '',
      taxIdAlert: '',
      agreed: false,
      submitAlert: '',
      activeAccordion: null,

      //check
      nameCheck: false,
      phoneIsValid: false,
      emailCheck: false,

      //Phone-mpn
      dropdownOptions: {
        disabled: false,
        showDialCodeInList: true,
        showDialCodeInSelection: false,
        showFlags: true,
        width: '100%',
      },
      inputOptions: {
        placeholder: this.$t('shopping-cart.key027'),
        showDialCode: true,
      },
      //運送方式
      shippingOptions: [
        // 下拉框选项
        { value: 1, label: this.$t('shopping-cart.key028') },
        { value: 2, label: this.$t('shopping-cart.key029') },
      ],
      shippingValueMap: {
        1: 80,
        2: 200,
      },
    };
  },
  watch: {
    cartData: {
      handler(newVal) {
        this.localCartData = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
    },
    phoneServerCheck(boolean) {
      if (boolean) {
        this.phoneAlert = '';
      } else {
        this.phoneAlert = this.$t('shopping-cart.key031');
      }
    },
  },
  mounted() {
    this.initTwzipcode();

    $('#zipcode2').twzipcode('set', {
      county: this.localCartData.address.county,
      district: this.localCartData.address.district,
      zipcode: this.localCartData.address.zipCode,
    });
  },
  computed: {
    shippingValue() {
      return this.shippingValueMap[this.localCartData.shipping] || 0;
    },
    formattedMessage() {
      return this.$t('shopping-cart.key041', {
        userPolicyLink: `<a href="#/${this.currentLanguage}/legal/user-policy" target="_blank">${this.$t('register.key025')}</a>`,
        privacyPolicyLink: `<a href="#/${this.currentLanguage}/legal/privacy" target="_blank">${this.$t('register.key026')}</a>`,
      });
    },
  },
  methods: {
    //address
    initTwzipcode() {
      const self = this;
      $('#zipcode2').twzipcode({
        zipcodeIntoDistrict: true,
        css: ['city form-control', 'town form-control'],
        countyName: 'city',
        districtName: 'town',
        hideCounty: [],
        hideDistrict: [],
        onCountySelect: function () {
          self.updateAddress();
        },
        onDistrictSelect: function () {
          self.updateAddress();
        },
      });
    },
    //get address
    updateAddress() {
      const addressInfo = $('#zipcode2').twzipcode('get');

      this.localCartData.address.county = addressInfo.county.val();
      this.localCartData.address.district = addressInfo.district.val();
      this.localCartData.address.zipCode = addressInfo.zipcode.val();

      if (this.checkAddress()) {
        this.addressAlert = '';
        this.$emit('update-data', this.localCartData);
      } else {
        this.addressAlert = this.$t('shopping-cart.key031');
      }
    },
    updateAddressDetail() {
      this.localCartData.address.addressDetail = this.localCartData.address.addressDetail.trim();

      if (this.checkAddress()) {
        this.addressAlert = '';
        this.$emit('update-data', this.localCartData);
      } else {
        this.addressAlert = this.$t('shopping-cart.key031');
      }
    },
    checkAddress() {
      if (this.localCartData.address.county && this.localCartData.address.zipCode && this.localCartData.address.district && this.localCartData.address.addressDetail) {
        this.addressAlert = '';
        return true;
      } else {
        return false;
      }
    },
    //phone
    phoneObject: function (object) {
      if (object.valid === true) {
        this.phoneIsValid = true;
        this.$emit('update-data', this.localCartData);
      } else {
        this.phoneIsValid = false;
      }
    },
    PhoneBlur() {
      if (!this.phoneIsValid) {
        this.phoneAlert = this.$t('shopping-cart.key031');
        return false;
      } else {
        this.phoneAlert = '';
      }
    },
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.$nextTick(() => {
          $('.accordion-item').eq(index).find('.accordion-content').slideUp();
        });
        this.activeAccordion = null;
      } else {
        this.$nextTick(() => {
          $('.accordion-item').find('.accordion-content').slideUp();
        });
        this.$nextTick(() => {
          const $currentItem = $('.accordion-item').eq(index).find('.accordion-content');
          $currentItem.slideDown();
        });
        this.activeAccordion = index;
      }
    },
    validateName() {
      const specialCharPattern = /[^a-zA-Z0-9\u4e00-\u9fa5]/g;
      if (specialCharPattern.test(this.localCartData.name)) {
        this.localCartData.name = this.localCartData.name.replace(specialCharPattern, '');
        this.nameAlert = this.$t('shopping-cart.key032');
        return;
      }

      if (this.localCartData.name.length > 20) {
        this.localCartData.name = this.localCartData.name.slice(0, 20);
        this.nameAlert = this.$t('shopping-cart.key033');
      } else {
        this.nameAlert = '';
      }
    },
    validateNameOnBlur() {
      if (this.localCartData.name.length < 2) {
        this.nameAlert = this.$t('shopping-cart.key034');
        return false;
      } else {
        this.nameAlert = '';
        this.nameCheck = true;
        this.$emit('update-data', this.localCartData);
        return true;
      }
    },
    removeSpecialCharsFromEmail() {
      const specialCharPattern = /[^\w@.-]/g;
      if (specialCharPattern.test(this.localCartData.email)) {
        this.localCartData.email = this.localCartData.email.replace(specialCharPattern, '');
        this.emailAlert = this.$t('shopping-cart.key032');
        return;
      }
    },
    validateEmailOnBlur() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.localCartData.email)) {
        this.emailAlert = this.$t('shopping-cart.key035');
        return false;
      } else {
        this.emailAlert = '';
        if (this.localCartData.email.toLowerCase().includes('mali')) {
          this.emailAlert = this.$t('shopping-cart.key036');
        }
        this.emailCheck = true;
        this.$emit('update-data', this.localCartData);
        return true;
      }
    },
    updateShipping() {
      if (this.localCartData.shipping != null) {
        this.shippingAlert = '';
        this.$emit('update-data', this.localCartData);
      } else {
        this.shippingAlert = this.$t('shopping-cart.key031');
      }
    },
    onInputTaxId() {
      this.localCartData.taxId = this.localCartData.taxId.replace(/\D/g, '');
    },
    validateTaxId() {
      const taxIdString = String(this.localCartData.taxId);

      if (taxIdString.length !== 0 && taxIdString.length !== 8) {
        this.taxIdAlert = this.$t('shopping-cart.key031');
      } else {
        this.taxIdAlert = '';
      }
    },
    formatBreak(content) {
      if (!content) {
        return;
      } else {
        return content.replace(/\n/g, '<br/>');
      }
    },
    proceedToPayment() {
      if (!this.agreed) {
        this.submitAlert = this.$t('shopping-cart.key038');
        return;
      }

      let check = true;
      this.validateNameOnBlur();
      this.validateEmailOnBlur();
      this.validateTaxId();
      this.PhoneBlur();

      if (!this.nameCheck || !this.emailCheck) {
        this.submitAlert = this.$t('shopping-cart.key037');
        check = false;
        return;
      } else if (this.localCartData.taxId != '' && this.taxIdAlert != '') {
        this.submitAlert = this.$t('shopping-cart.key037');
        check = false;
        return;
      }

      if (this.cartData.hasPhysicalItem) {
        if (!this.phoneIsValid) {
          this.phoneAlert = this.$t('shopping-cart.key031');
          this.submitAlert = this.$t('shopping-cart.key037');
          check = false;
        }

        if (!(this.cartData.address.county && this.cartData.address.zipCode && this.cartData.address.district && this.cartData.address.addressDetail)) {
          this.addressAlert = this.$t('shopping-cart.key031');
          this.submitAlert = this.$t('shopping-cart.key037');
          check = false;
        }

        if (!this.cartData.shipping) {
          this.shippingAlert = this.$t('shopping-cart.key031');
          this.submitAlert = this.$t('shopping-cart.key037');
          check = false;
        }
      } else if (!this.phoneIsValid) {
        this.localCartData.phone = '';
        this.$emit('update-data', this.localCartData);
        this.phoneAlert = '';
      }

      if (check) {
        this.submitAlert = '';
        this.$emit('submit-order');
      }
    },
    prevStep() {
      this.$emit('prev');
    },
  },
};
</script>
