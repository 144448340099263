<template>
  <section class="reset-password">
    <h1>{{ $t('reset-password.key001') }}</h1>
    <form class="reset_box" v-if="!resetSuccess" @submit.prevent="reset">
      <div class="reset_list">
        <span class="title">{{ $t('register.key002') }}</span>
        <div class="eye_fixed">
          <input type="text" v-model="userEmail" disabled />
        </div>
      </div>
      <div class="reset_list verify_email" v-if="verifyShow">
        <span class="title"></span>
        <div class="eye_fixed text">
          <span>{{ $t('register.key003') }}</span>
          <span class="change_email" @click="changeEmail">
            {{ $t('register.key004') }}
          </span>
        </div>
      </div>

      <div class="verify verify_email" v-if="verifyShow">
        <span class="title">
          <i class="bi bi-shield-fill-exclamation"></i>
          {{ $t('member-information.key029') }}
        </span>
        <div class="eye_fixed">
          <input type="text" v-model="verify" :placeholder="$t('register.key006')" />
          <div class="text">
            <p class="resend" :class="{ disResend: !resendShow }" @click="sandVerifyCode">
              <i class="bi bi-arrow-clockwise"></i>
              <span class="sendText">{{ $t('register.key007') }}</span>
              <span v-if="!resendShow">&nbsp;{{ countdown }}s</span>
            </p>
            <p class="verify_time">
              <span>{{ $t('register.key008') }} {{ formatTime(timer) }}</span>
            </p>
          </div>
          <div class="alert alertVerify">{{ alertVerify }}</div>
        </div>
      </div>

      <div class="label_box verify_email" v-if="verifySuccess">
        <span class="title"></span>
        <div class="eye_fixed text">
          <span>
            <i class="bi bi-check-circle-fill"></i>
            &nbsp;{{ $t('register.key005') }}
          </span>
        </div>
      </div>

      <div class="label_box" v-if="(verifyShow && !verifySuccess) || (!verifyShow && verifySuccess)">
        <span class="title">{{ $t('reset-password.key004') }}</span>
        <div class="eye_fixed">
          <input :type="passwordVisibleNew ? 'text' : 'password'" v-model="passwordNew" :placeholder="$t('reset-password.key005')" name="passwordNew" />
          <div class="eye_btn" @click="togglePasswordVisibility('new')">
            <i class="bi" :class="passwordVisibleNew ? 'bi-eye-fill' : 'bi-eye'"></i>
          </div>
        </div>
      </div>

      <div class="label_box" v-if="(verifyShow && !verifySuccess) || (!verifyShow && verifySuccess)">
        <span class="title">{{ $t('reset-password.key006') }}</span>
        <div class="eye_fixed">
          <input :type="passwordVisibleNew ? 'text' : 'password'" v-model="passwordNewCheck" :placeholder="$t('reset-password.key007')" name="passwordNewCheck" />
          <div class="eye_btn" @click="togglePasswordVisibility('new')">
            <i class="bi" :class="passwordVisibleNew ? 'bi-eye-fill' : 'bi-eye'"></i>
          </div>
        </div>
      </div>
      <div class="alert center_alert">{{ alert }}</div>
      <button type="button" class="submit-button" v-if="(!verifyShow && !verifySuccess) || (verifyShow && verifySuccess)" @click="sandVerifyCode">
        {{ $t('register.key013') }}
      </button>
      <button type="submit" class="submit-button" v-if="(verifyShow && !verifySuccess) || (!verifyShow && verifySuccess)">
        {{ $t('forgot-password.key008') }}
      </button>
    </form>

    <div v-if="resetSuccess" class="reset-success">
      <div class="flex">
        <h2 class="title">{{ $t('reset-password.key009') }}</h2>
        <dotlottie-player
          id="check-box"
          src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
          background="transparent"
          speed="1"
          autoplay=""
        ></dotlottie-player>
      </div>
      <p>{{ $t('reset-password.key010', { countdownJump }) }}</p>
    </div>
  </section>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { getUserInfo, resetPSWSendVerCode, resetPSWVerCodeCheck, resetPSW } from '@/api/apiService';
import Loading from '@/components/Loading.vue';
import { mapState } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    Loading,
  },
  data() {
    return {
      userEmail: '',
      verify: '',
      passwordNew: '',
      passwordNewCheck: '',
      alert: '',
      passwordVisibleCurrent: false,
      passwordVisibleNew: false,
      showReset: true,
      resetSuccess: false,
      countdown: 0,
      timer: 0,
      countdownJump: 5,
      verifyShow: false,
      verifySuccess: false,
      alertVerify: '',
      loading: false,
      resendShow: false,
    };
  },

  async mounted() {
    await this.getUserInfo();
    if (this.resetSuccess) {
      this.playAnimation();
    }
    if (this.loginMethod !== 1) {
      this.$router.push(`/${this.currentLanguage}/member/member-information`);
    }
  },
  computed: {
    ...mapState(['jwt']),
    ...mapState(['loginMethod']),
  },
  methods: {
    //getUserInfo
    async getUserInfo() {
      try {
        this.loading = true;
        const userData = await getUserInfo(this.jwt);
        this.userEmail = userData.account;
        this.loading = false;
      } catch (error) {
        const statusCode = error.response.status;
        this.loading = false;
        alert(`${this.$t('register.key028')}，${statusCode}`);
      }
    },
    //發送驗證碼
    async sandVerifyCode() {
      this.alert = '';
      this.alertVerify = '';
      try {
        this.resendShow = false;
        this.loading = true;
        await resetPSWSendVerCode(this.jwt);
        this.verifyShow = true;
        this.resetCountdown();
        this.startCountdown();
        this.startTimer();
        this.loading = false;
      } catch (error) {
        const statusCode = error.response.status;
        this.loading = false;
        if (statusCode === this.$statusCodes.MethodNotAllowed) {
          this.alert = this.$t('forgot-password.key009');
        } else {
          this.alert = this.$t('register.key028');
        }
      }
    },
    // 驗證碼
    async verifyCode() {
      try {
        const verifyCode = {
          code: this.verify,
        };
        this.loading = true;
        const response = await resetPSWVerCodeCheck(verifyCode, this.jwt);
        this.PSWjwt = response.data.data;
        this.verifyShow = false;
        this.verifySuccess = true;
        this.loading = false;
      } catch (error) {
        const statusCode = error.response.status;
        if (statusCode === this.$statusCodes.BadRequest) {
          this.alertVerify = this.$t('register.key029');
        } else {
          this.alertVerify = this.$t('register.key028');
        }
        this.loading = false;
      }
    },
    reset() {
      if (this.password === '') {
        this.alert = this.$t('reset-password.key011');
        return;
      }
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
      if (!passwordRegex.test(this.passwordNew)) {
        this.alert = this.$t('reset-password.key012');
        return;
      }

      if (this.passwordNew !== this.passwordNewCheck) {
        this.alert = this.$t('reset-password.key013');
        return;
      }
      if (!this.verifySuccess) {
        this.alert = '＊驗證碼尚未認證通過';
      } else {
        this.updatePassword();
      }
    },
    async updatePassword() {
      try {
        const PSWData = {
          password: this.passwordNew,
          jwt: this.PSWjwt,
        };

        this.loading = true;
        await resetPSW(PSWData, this.jwt);
        this.loading = false;
        this.resetSuccess = true;
        this.titleClose = false;

        const countdownInterval = setInterval(() => {
          if (this.countdownJump > 0) {
            this.countdownJump--;
          } else {
            clearInterval(countdownInterval);
            this.$router.push({
              path: `/${this.currentLanguage}`,
            });
          }
        }, 1000);
      } catch (error) {
        this.loading = false;
        this.alert = this.$t('register.key028');
      }
    },
    playAnimation() {
      this.$nextTick(() => {
        const checkBox = document.querySelector('#check-box');
        if (checkBox) {
          setInterval(() => {
            checkBox.play();
          }, 0);
        }
      });
    },
    togglePasswordVisibility(field) {
      if (field === 'current') {
        this.passwordVisibleCurrent = !this.passwordVisibleCurrent;
      } else if (field === 'new') {
        this.passwordVisibleNew = !this.passwordVisibleNew;
      }
    },
    //驗證碼發送_倒數
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.interval);
          this.resendShow = true;
        }
      }, 1000);
    },
    //驗證碼期限_倒數
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.timerInterval);
          this.alertVerify = this.$t('register.key019');
        }
      }, 1000);
    },
    //驗證碼期限_format
    formatTime(seconds) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;
      if (h > 0) {
        return `${h}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
      } else {
        return `${m}:${s.toString().padStart(2, '0')}`;
      }
    },
    //重設倒數時間 > 驗證碼發送 & 驗證碼期限
    resetCountdown() {
      this.countdown = 60;
      this.timer = 600;
    },
    //停止倒數
    stopCountdown() {
      clearInterval(this.interval);
      clearInterval(this.timerInterval);
    },
  },
  watch: {
    resetSuccess(newValue) {
      if (newValue) {
        this.playAnimation();
      }
    },
    verify(newVal) {
      if (newVal.length === 6) {
        this.verifyCode();
      }
    },
  },
};
</script>
