<template>
  <div class="modal binding-popup">
    <div class="mask"></div>
    <div class="modal-content">
      <div class="flex">
        <h1>{{ $t('serial-number.key029') }}</h1>
        <router-link :to="`/${currentLanguage}/member/serial-number/account-binding`">
          <button class="btn">
            {{ $t('serial-number.key030') }}
          </button>
        </router-link>
        <router-link :to="`/${currentLanguage}/member/serial-number/registration-binding`">
          <button class="btn">
            {{ $t('serial-number.key031') }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BindingPopup',
  data() {
    return {};
  },
  methods: {},
};
</script>
