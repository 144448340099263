import axios from 'axios';

const s3Uploader = {
  /** @param {File} file */
  async uploadToS3(file, signedUrl) {
    const binaryData = await file.arrayBuffer();
    try {
      await axios.put(signedUrl, binaryData, {
        headers: { 'Content-Type': file.type },
      });
      return signedUrl.split('?')[0]; // Return the URL without the query string
    } catch (error) {
      console.error('Error uploading to S3:', error);
      throw error;
    }
  },
};

export const imageUploader = {
  // Select an image
  selectImage(event) {
    const file = event.target.files[0];
    if (file) {
      return {
        file,
        preview: URL.createObjectURL(file),
      };
    }
    return null;
  },

  // Create a preview URL for the image
  previewImage(file) {
    return URL.createObjectURL(file);
  },

  // Upload the image to the specified URL
  async uploadImage(file, uploadUrl) {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  },

  // Validate the selected image
  validateImage(file, maxSizeInMB = 5, allowedTypes = ['image/jpeg', 'image/png']) {
    if (!file) {
      return 'Please select a file';
    }
    if (!allowedTypes.includes(file.type)) {
      return 'Unsupported file type';
    }
    if (file.size > maxSizeInMB * 1024 * 1024) {
      return `File size cannot exceed ${maxSizeInMB}MB`;
    }
    return null;
  },

  s3Uploader,
};
