<template>
  <div class="forgot-password">
    <h1 class="title" v-if="titleClose">
      {{ $t('forgot-password.key001') }}
    </h1>
    <form @submit.prevent="forgot" v-if="!resetSuccess">
      <div class="username">
        <i class="bi bi-person-fill"></i>
        <input v-model="usernameF" type="text" id="username" name="username" :placeholder="$t('register.key002')" :disabled="verifyShow || verifySuccess" />
        <div class="verify_email" v-if="verifyShow">
          <span>{{ $t('register.key003') }}</span>
          <span class="change_email" @click="changeEmail">
            {{ $t('register.key004') }}
          </span>
        </div>
        <div class="verifySuccess verify_email" v-if="verifySuccess">
          <p>
            <i class="bi bi-check-circle-fill"></i>
            &nbsp;{{ $t('register.key005') }}
          </p>
        </div>
        <div class="verify" v-if="verifyShow">
          <i class="bi bi-shield-fill-exclamation"></i>
          <input v-model="verifyR" type="text" id="verify" name="verify" :placeholder="$t('register.key006')" />
          <div class="verify_email">
            <p class="resend" :class="{ disResend: !resendShow }" @click="sandVerifyCode">
              <i class="bi bi-arrow-clockwise"></i>
              <span class="sendText">{{ $t('register.key007') }}</span>
              <span v-if="!resendShow">&nbsp;{{ countdown }}s</span>
            </p>
            <span class="verify_time"> {{ $t('register.key008') }} {{ formatTime(timer) }} </span>
          </div>
          <div class="alert alertVerify">{{ alertVerify }}</div>
        </div>
        <div class="password" v-if="(verifyShow && !verifySuccess) || (!verifyShow && verifySuccess)">
          <i class="bi bi-key-fill"></i>
          <input v-model="passwordF" :type="passwordVisible ? 'text' : 'password'" id="password" name="password" :placeholder="$t('register.key009')" />
          <div class="eye_btn" @click="togglePasswordVisibility">
            <i class="bi" :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i>
          </div>
        </div>
        <div class="password" v-if="(verifyShow && !verifySuccess) || (!verifyShow && verifySuccess)">
          <i class="bi bi-key-fill"></i>
          <input v-model="passwordCheck" :type="passwordVisible ? 'text' : 'password'" id="passwordCheck" name="password" :placeholder="$t('register.key010')" />
          <div class="eye_btn" @click="togglePasswordVisibility">
            <i class="bi" :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i>
          </div>
        </div>
      </div>
      <div class="alert">{{ alertF }}</div>
      <button type="button" class="login_btn" v-if="(!verifyShow && !verifySuccess) || (verifyShow && verifySuccess)" @click="sandVerifyCode">
        {{ $t('register.key013') }}
      </button>
      <button type="submit" class="login_btn" v-if="(verifyShow && !verifySuccess) || (!verifyShow && verifySuccess)">
        {{ $t('forgot-password.key008') }}
      </button>

      <div class="link">
        <router-link :to="`/${currentLanguage}/member-login/login`">
          {{ $t('register.key015') }}
        </router-link>
      </div>
    </form>

    <div v-if="resetSuccess" class="reset-success">
      <h1 class="title">{{ $t('forgot-password.key002') }}</h1>
      <dotlottie-player
        id="check-box"
        src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
        background="transparent"
        speed="1"
        autoplay=""
      ></dotlottie-player>
      <p>{{ $t('register.key017') }}</p>
      <router-link :to="`/${currentLanguage}/member-login/login`">
        <button class="login_btn">{{ $t('register.key015') }}</button>
      </router-link>
    </div>
  </div>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { sendVerificationForgetPSW, checkVerificationForgetPSW, updatePassword } from '@/api/apiService';
import Loading from '@/components/Loading.vue';

export default {
  name: 'ForgotPassword',
  props: {},
  components: {
    Loading,
  },
  data() {
    return {
      jwt: '',
      reasonF: 'changePasswordVerify',
      usernameF: '',
      passwordF: '',
      passwordCheck: '',
      passwordVisible: false,
      verifyR: '',
      alertF: '',
      alertVerify: '',
      loading: false,
      resetSuccess: false,
      countdown: 5,
      titleClose: true,
      verifySuccess: false,
      verifyShow: false,
      resendShow: false,
      countdownJump: 5,
      timer: 0,
    };
  },
  mounted() {
    if (this.resetSuccess) {
      this.playAnimation();
    }
  },
  watch: {
    verifyR(newVal) {
      if (newVal.length === 6) {
        this.verifyCode();
      }
    },
  },
  methods: {
    //更改信箱
    changeEmail() {
      this.verifyShow = false;
      this.resendShow = false;
      this.stopCountdown();
    },
    //驗證碼發送_倒數
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.interval);
          this.resendShow = true;
        }
      }, 1000);
    },
    //驗證碼期限_倒數
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.timerInterval);
          this.alertVerify = this.$t('register.key019');
        }
      }, 1000);
    },
    //驗證碼期限_format
    formatTime(seconds) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;
      if (h > 0) {
        return `${h}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
      } else {
        return `${m}:${s.toString().padStart(2, '0')}`;
      }
    },
    //重設倒數時間 > 驗證碼發送 & 驗證碼期限
    resetCountdown() {
      this.countdown = 60;
      this.timer = 600;
    },
    //停止倒數
    stopCountdown() {
      clearInterval(this.interval);
      clearInterval(this.timerInterval);
    },
    //發送驗證碼
    async sandVerifyCode() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.usernameF)) {
        this.alertF = this.$t('register.key020');
        return;
      } else {
        this.alertF = '';
        this.alertVerify = '';
        try {
          const accountData = {
            account: this.usernameF,
          };
          this.loading = true;
          await sendVerificationForgetPSW(accountData);
          this.verifyShow = true;
          this.resetCountdown();
          this.startCountdown();
          this.startTimer();
          this.loading = false;
        } catch (error) {
          const statusCode = error.response.status;
          this.loading = false;
          if (statusCode === this.$statusCodes.MethodNotAllowed) {
            this.alertF = this.$t('forgot-password.key009');
          } else {
            this.alertF = this.$t('register.key028');
          }
        }
      }
    },
    //重新發送驗證碼
    resendCode() {
      this.alertVerify = '';
      this.stopCountdown();
      this.resetCountdown();
      this.startCountdown();
      this.startTimer();
      this.resendShow = false;
    },
    //驗證碼
    async verifyCode() {
      try {
        const accountData = {
          account: this.usernameF,
          code: this.verifyR,
        };
        this.loading = true;
        const response = await checkVerificationForgetPSW(accountData);
        this.jwt = response.data.data;
        this.verifyShow = false;
        this.verifySuccess = true;
        this.loading = false;
      } catch (error) {
        const statusCode = error.response.status;
        if (statusCode === this.$statusCodes.BadRequest) {
          this.alertVerify = this.$t('register.key029');
        } else {
          this.alertVerify = this.$t('register.key028');
        }
        this.loading = false;
      }
    },
    forgot() {
      // 1. 檢查密碼是否符合長度8-16位
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
      if (!passwordRegex.test(this.passwordF)) {
        this.alertF = this.$t('register.key022');
        return;
      }

      // 2. 檢查兩次輸入的密碼是否一致
      if (this.passwordF !== this.passwordCheck) {
        this.alertF = this.$t('register.key023');
        return;
      }
      // 3. 檢查“驗證碼”是否空白
      if (this.verifyR === '') {
        this.alertF = this.$t('register.key030');
        return;
      }
      if (!this.verifySuccess) {
        this.alertF = this.$t('forgot-password.key010');
      } else {
        this.updatePassword();
      }
    },
    async updatePassword() {
      try {
        const data = {
          account: this.usernameF,
          password: this.passwordF,
          jwt: this.jwt,
        };
        this.loading = true;
        await updatePassword(data);
        this.loading = false;
        this.resetSuccess = true;
        this.titleClose = false;

        const countdownInterval = setInterval(() => {
          if (this.countdownJump > 0) {
            this.countdownJump--;
          } else {
            clearInterval(countdownInterval);
            this.$router.push({
              path: `/${this.currentLanguage}/member-login/login`,
            });
          }
        }, 1000);
      } catch (error) {
        this.loading = false;
        this.alertR = this.$t('register.key028');
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>

<style lang="scss"></style>
