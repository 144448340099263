<template>
  <section class="wrapper download_page faq">
    <h1>{{ $t('faq.key004') }}</h1>
    <div class="block">
      <h2>{{ $t('redemption-guide.key001') }}</h2>
      <br />
      <img :src="`${$cloudFront}/Image/Public/RedemptionGuide/zh_tw/redemptionGuide_PC.png`" alt="" />
      <br />
      <br />
      <h2>{{ $t('redemption-guide.key002') }}</h2>
      <br />
      <img :src="`${$cloudFront}/Image/Public/RedemptionGuide/zh_tw/redemptionGuide_Web.png`" alt="" />
    </div>
  </section>
</template>
<script>
export default {
  name: 'FAQ',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
