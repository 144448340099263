import { mapState, mapActions } from 'vuex';
import { HttpStatusCode as StatusCodes } from 'axios';

const globalMixins = {
  computed: {
    ...mapState(['currentLanguage']),
  },
  methods: {
    ...mapActions(['setLanguage']),
  },
};

export { globalMixins, StatusCodes };
