<template>
  <section class="download_page contact_us">
    <h1 v-show="order_failed">{{ $t('common.contactUs_key001') }}</h1>
    <img src="../assets/img/public/Line_QRcode_Big.svg" alt="" />
    <p>
      ID：
      <a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053"><span>@beatday</span></a>
    </p>
    <p>
      {{ $t('common.contactUs_key002') }}
      <a href="mailto:contact@beatday.com"><span>contact@beatday.com</span></a>
      {{ $t('common.contactUs_key003') }}
      <br />
      {{ $t('common.contactUs_key004') }}
    </p>
  </section>
</template>
<script>
export default {
  name: 'contactUs',
  components: {},
  data() {
    return {
      order_failed: false,
    };
  },
  props: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import '../assets/styles/pages/downloadsTutorials.scss';
</style>
