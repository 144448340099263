<template>
  <app-header></app-header>
  <div class="orderComplete">
    <section class="section_flex guest_order">
      <div class="left" v-if="status">
        <h1 class="title">{{ $t('order-complete.key001') }}</h1>
        <dotlottie-player
          id="check-box"
          class="check_icon"
          src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
          background="transparent"
          speed="1"
          autoplay=""
        ></dotlottie-player>
        <p>{{ $t('order-complete.key002') }}</p>
        <p class="heightline">{{ orderId }}</p>
        <p>{{ $t('order-complete.key003') }}</p>
        <p class="heightline">{{ email }}</p>
      </div>
      <div class="left" v-else>
        <h1 class="title">{{ $t('order-complete.key004') }}</h1>
        <img src="../assets/img/public/failed.png" alt="" class="check_icon" />
        <p>{{ $t('order-complete.key005') }}</p>
        <p>{{ $t('order-complete.key005') }}</p>
        <h1 class="title contactTitle">
          {{ $t('order-complete.key007') }}
        </h1>
        <contact-us></contact-us>
      </div>
      <div class="right" v-if="!hasAuth">
        <h1 class="title">{{ $t('order-complete.key008') }}</h1>
        <div class="member-login">
          <Register :username="email" @stageUpdated="handleStageUpdate"></Register>
          <third-party-login v-show="!stage"></third-party-login>
        </div>
      </div>
      <div class="right" v-else>
        <h1 class="title">{{ $t('order-complete.key009') }}</h1>
        <div class="pickup">
          <img src="../assets/img/cartOrder/pickup_01.svg" alt="" />
          <p>{{ $t('order-complete.key010') }}</p>
        </div>
        <img src="../assets/img/cartOrder/arrow.svg" alt="" class="arrow" />
        <div class="pickup">
          <img src="../assets/img/cartOrder/pickup_02.svg" alt="" />
          <p>{{ $t('order-complete.key011') }}</p>
        </div>
        <img src="../assets/img/cartOrder/arrow.svg" alt="" class="arrow" />
        <div class="pickup">
          <div class="pickup_img">
            <img src="../assets/img/cartOrder/pickup_03.svg" alt="" />
          </div>
          <p>{{ $t('order-complete.key012') }}</p>
        </div>
        <img src="../assets/img/cartOrder/arrow.svg" alt="" class="arrow" />
        <div class="pickup">
          <img src="../assets/img/cartOrder/pickup_04.svg" alt="" />
          <p>{{ $t('order-complete.key013') }}</p>
        </div>
        <router-link :to="`/${currentLanguage}/member/serial-number/serial-number-default`" class="click_btn">
          <div class="button_style_linear">
            <p>{{ $t('order-complete.key014') }}</p>
          </div>
        </router-link>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import Register from '@/pages/login/Register.vue';
import thirdPartyLogin from '@/components/login/ThirdPartyLogin.vue';
import contactUs from '@/components/ContactUs.vue';
import { removeCartItems, removeCartItemExpiry } from '@/localStorage/cartStorage';

export default {
  name: 'ShoppingCart',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    Register,
    'third-party-login': thirdPartyLogin,
    'contact-us': contactUs,
  },
  data() {
    return {
      email: '',
      status: null,
      hasAuth: null,
      orderId: '',
      stage: false,
    };
  },
  mounted() {
    this.status = this.$route.query.status === '1';
    this.email = decodeURIComponent(this.$route.query.email);
    this.hasAuth = this.$route.query.hasAuth === '1';
    this.orderId = decodeURIComponent(this.$route.query.orderId);

    if (this.status) {
      removeCartItems();
      removeCartItemExpiry();
    }
  },
  methods: {
    handleStageUpdate(stageCompleted) {
      this.stage = stageCompleted;
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/pages/login.scss';
</style>
