<template>
  <app-header></app-header>
  <section class="privacy_page return-policy-MizukiLin wrapper">
    <div class="wpb_wrapper">
      <div class="wpb_wrapper">
        <h1>【VDay 誕生日】</h1>
        <h2>退票規則</h2>
        <ul>
          <li>
            本活動票券購票完成後恕不接受更改日期、場次與時段。若消費者有更改需求，請在退票期間內完成退票後重新購票；本活動票券的退票作業僅限於本活動虛擬序號尚未兌換完成為限，並請於收到本活動票券虛擬序號7日內申請退票，虛擬序號一經兌換或收到虛擬序號逾7日未辦理退票者，恕不接受退票。
          </li>
          <li>
            請留意，套票中的活動票券退票條件與一般活動票券相同，而獨家周邊商品均有數量限制，無法提供換貨服務，消費者如有退貨需求，請於收到獨家周邊商品後7日內辦理，若衍生相關運費由消費者自行吸收；辦理獨家周邊商品退貨時(以官網公告價值為準)，請保持商品及包裝的完整性，並提供購買憑證。
          </li>
          <li>關於本活動節目退票及相關事宜如有不清楚之處，請使用BEATDAY LINE 與客服人員聯繫辦理，本公司將盡快於上班日回覆。</li>
        </ul>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</template>
<script>
// import { getSomething } from "@/api/apiService";
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
export default {
  name: 'LegalPage',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
  },
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {
    // async fetchData() {
    //   try {
    //     const response = await getSomething();
    //     this.test = response;
    //   } catch (error) {
    //     // Handle error
    //   }
    // },
  },
};
</script>

<style lang="scss"></style>
